import { React, useContext } from "react";
import { auth } from "../firebase";
import { Link } from "react-router-dom";
// import { useAuthState } from "react-firebase-hooks/auth";

import { Box, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
// import { useTheme } from "@mui/material/styles";

import userContext from "../contexts/userContext";

const Topbar = ({
  themeMode,
  themeToggler,
  setThemePalette,
  onSidebarOpen,
  paletteType
}) => {
  // const theme = useTheme();
  const { user, loading } = useContext(userContext);
  if (loading) return null;

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={"100%"}>
      <Box display={"flex"} alignItems={"center"}>
        <Box marginRight={{ xs: 1, sm: 2 }}>
          {onSidebarOpen && (
            <IconButton
              onClick={onSidebarOpen}
              aria-label="Menu"
              sx={{
                marginTop: 2,
                marginLeft: 1,
                backgroundColor: "rgba(255,255,255,0.5)"
              }}>
              <MenuIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box display={"flex"} alignItems={"center"} sx={{ margin: 2 }}>
        {user ? (
          <Box display={"flex"} alignItems={"right"}>
            <Box>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => auth.signOut()}>
                Sign out
              </Button>
            </Box>
          </Box>
        ) : (
          <Box display={"flex"} alignItems={"right"}>
            <Box mr={2}>
              <Button
                component={Link}
                to="/login"
                variant="text"
                color="primary"
                size="large">
                Log in
              </Button>
            </Box>
            {window.location.hostname === "localhost" && (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/signup"
                  size="large">
                  Sign up
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Topbar;
