import firebase from "firebase/app";
import "@firebase/auth";
import "@firebase/firestore";
import "@firebase/functions";
import "@firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDSz-BxsKrAKOzwiHUGbWlufToWTWdqcnI",
  authDomain: "veyancer.firebaseapp.com",
  projectId: "veyancer",
  storageBucket: "veyancer.appspot.com",
  messagingSenderId: "841915250958",
  appId: "1:841915250958:web:93a34a89b03c69fd7e7f5f",
  measurementId: "G-51SWJFT9TF"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const functions = firebase.app().functions("europe-west1");
if (window.location.hostname === "localhost")
  functions.useEmulator("localhost", 5001);
const storage = firebase.storage();
const db = firebase.firestore();
db.settings({
  ignoreUndefinedProperties: true
}); // often schema data will be empty
const auth = firebase.auth();
export { firebase, db, auth, functions, storage };
