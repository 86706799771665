import { db } from "../firebase";
import { currentSchemaId } from "../schemas/schemaUtils";

const addressDataCollector = async (currentState) => {
  const uprn = currentState.uprn.toString(); // need toString?
  const doc = await db.collection("properties").doc(uprn).get();
  const propertyRecord = await doc.data();
  const { line_1, line_2, post_town, postal_county, postcode } = propertyRecord;

  const evidence = [
    {
      type: "electronic_record",
      record: {
        type: "paf",
        source: {
          name: "Royal Mail Postcode Address File record via ideal-postcodes.co.uk"
        }
      }
    }
  ];
  const addressClaim = {
    // uprn,
    verification: {
      trust_framework: "uk_pdtf",
      time: new Date().toISOString(),
      evidence
    },
    claims: {
      schema: currentSchemaId,
      path: "/propertyPack/materialFacts/propertyToBeSold/address",
      data: {
        address1: line_1,
        address2: line_2,
        town: post_town,
        county: postal_county,
        postcode
      },
      attribution:
        "https://ideal-postcodes.co.uk/paf-end-user-licence-20150708.pdf"
    }
  };

  console.log(addressClaim);
  return [addressClaim];
};

export default addressDataCollector;
