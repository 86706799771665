import React, { useState } from "react";
import { db } from "../firebase";
import { v4 as uuidv4 } from "uuid";

import {
  TextField,
  IconButton,
  Typography,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import { PersonAdd, Send } from "@mui/icons-material";

import Loader from "./Loader";

// const USER_ROLES = ["Editor", "Suggester", "Commenter", "Viewer"];

export default function AddSharerDialog({ profile, uprn }) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState();
  const [email, setEmail] = useState();
  const [invitationState, setInvitationState] = useState();
  const [coveringText, setCoveringText] = useState();
  const [matchingUser, setMatchingUser] = useState();
  const [processingSend, setProcessingSend] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setEmail("");
    setProcessingSend(false);
    setError(null);
    setInvitationState(null);
    setCoveringText("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmailChange = async (event) => {
    const value = event.target.value;
    setEmail(value);
    db.collection("users")
      .where("email", "==", value)
      .get()
      .then((snapshot) =>
        setMatchingUser(snapshot.empty ? null : snapshot.docs[0].data())
      );
  };

  const handleCoveringTextChange = (event) => {
    setCoveringText(event.target.value);
  };

  const matchedUserAlreadyIncluded = () => {
    if (!matchingUser) return;
    const existingShareIds = (
      profile.myProperties[uprn].sharingUserIds || []
    ).concat([profile.id]);
    return existingShareIds.includes(matchingUser.id);
  };

  const addUserButtonShown = () => {
    return matchingUser && !matchedUserAlreadyIncluded();
  };

  const inviteUserButtonEnabled = () => {
    return /\S+@\S+\.\S+/.test(email) && !matchedUserAlreadyIncluded();
  };

  const emailHelperText = () => {
    if (invitationState) return invitationState;
    if (matchingUser && matchingUser.email === profile.email)
      return "This is your email address!";
    if (matchedUserAlreadyIncluded()) return "Already shared with this user";
    if (addUserButtonShown()) return "Share with this user";
    if (inviteUserButtonEnabled()) return "Send email invitation";
    return "Enter a valid email address";
  };

  const handleInviteUser = () => {
    const myProperties = profile.myProperties;
    const sharingUserIds = (myProperties[uprn].sharingUserIds || []).concat(
      matchingUser.id
    );
    myProperties[uprn].sharingUserIds = sharingUserIds;
    db.collection("users").doc(profile.id).update({ myProperties });
    handleClose();
  };

  const handleInviteJoin = () => {
    // we create an email tagged as 'invitation' which will flow through the signup
    // process and connect that user into this resource
    setInvitationState("Sending invitation...");
    setProcessingSend(true);
    // we've already checked if the user already has an account
    // create an email to send
    const { name, id } = profile;
    const token = uuidv4();
    let inviteIntent = "Invited you to join their property at ";
    // Postmark only allows 80 characters for metadata fields
    inviteIntent =
      inviteIntent.length > 38
        ? inviteIntent.substring(0, 37) + '..."'
        : inviteIntent;
    const message = {
      // PascalCase keys as being passed as params to PostMark API
      Tag: "invitation",
      From: "invitations@veyancer.com",
      To: email,
      TemplateAlias: "share-invitation",
      TemplateModel: {
        name,
        inviteIntent,
        coveringText: coveringText,
        actionURL: `${window.location.origin}/auth/invite/${token}`
      },
      TrackOpens: true,
      Metadata: {
        initiatingUserId: id,
        token
      }
    };
    console.log(message);
    fetch(
      "https://europe-west1-veyancer.cloudfunctions.net/api/mail-send-with-template",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(message)
      }
    )
      .then((response) => {
        if (response.status !== 200) throw new Error("Postmark mailer error");
        handleClose();
      })
      .catch((error) => {
        setError("Sorry, an invitation error occurred");
        setInvitationState(null);
        setProcessingSend(false);
      });
    console.log(error);
  };

  return (
    <>
      <Tooltip title="Invite someone else" arrow>
        <IconButton
          onClick={handleOpen}
          color="primary"
          ml={1}
          className="tour-addSharer">
          <PersonAdd />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Invite someone else</DialogTitle>
        <DialogContent>
          <>
            <TextField
              type="string"
              fullWidth
              margin="normal"
              name="email"
              value={email}
              label="Add or invite someone.."
              placeholder="Email"
              variant="outlined"
              autoFocus
              required
              helperText={emailHelperText()}
              onChange={handleEmailChange}
              inputProps={{
                autoComplete: "off"
              }}
            />
            <TextField
              id="coveringText"
              name="coveringText"
              type="string"
              multiline
              minRows={5}
              required
              fullWidth
              margin="normal"
              label="Add a message"
              value={coveringText}
              onChange={handleCoveringTextChange}
            />
          </>
        </DialogContent>

        <DialogActions>
          {error && (
            <Typography variant="body2" color="error">
              Error: {error}
            </Typography>
          )}
          <Button variant="outlined" onClick={handleClose} color="primary">
            Cancel
          </Button>
          {addUserButtonShown() ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleInviteUser}
              endIcon={<PersonAdd />}>
              Share
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleInviteJoin}
              disabled={!inviteUserButtonEnabled() || processingSend}
              endIcon={processingSend ? <Loader size={20} /> : <Send />}>
              Invite to Join
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
