import React, { useContext } from "react";
import { Link } from "react-router-dom";

import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Chip
} from "@mui/material";

import userContext from "../contexts/userContext";
const googleMapsApiKey = "AIzaSyDSz-BxsKrAKOzwiHUGbWlufToWTWdqcnI";

function PropertyCard({ property }) {
  const { profile } = useContext(userContext);

  return (
    <Card sx={{ height: "100%" }}>
      <CardActionArea component={Link} to={`/properties/${property.uprn}`}>
        <CardMedia
          component="img"
          height="200"
          image={`https://maps.googleapis.com/maps/api/streetview?size=200x200&key=${googleMapsApiKey}&location=${property.latitude},${property.longitude}`}
          alt={property.line_1}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {property.line_1}
          </Typography>
          <Chip
            sx={{ mb: 0.5, p: 0, fontSize: "11px" }}
            label={profile.myProperties[property.uprn].role}
            variant="contained"
            color="primary"
            size="small"
          />
          <Typography variant="body2" color="text.secondary">
            {property.line_2} {property.line_3} {property.post_town},{" "}
            {property.postcode}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default PropertyCard;
