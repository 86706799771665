import { functions } from "../firebase";
import { currentSchemaId } from "../schemas/schemaUtils";

const epcCollector2 = async (currentState) => {
  const uprn = currentState.uprn.toString(); // need toString?
  console.log("in EPC2 cert collector");
  const epcCallable = functions.httpsCallable("collectors-epcCollector");
  const response = await epcCallable({ uprn });
  console.log(response);

  if (!response.data) return null;

  const evidence = [
    {
      type: "electronic_record",
      record: {
        type: "epc",
        source: {
          name: "Department for Levelling Up, Housing & Communities, Energy Performance of Buildings Register"
        }
      }
    }
  ];

  // const epcCertificateClaim = {
  //   uprn,
  //   verification: {
  //     trust_framework: "uk_pdtf",
  //     time: new Date().toISOString(),
  //     evidence
  //   },
  //   claims: {
  //     schema: currentSchemaId,
  //     path: "/propertyPack/energyPerformanceCertificate",
  //     data,
  //     attribution: "https://epc.opendatacommunities.org/docs/copyright"
  //   }
  // };

  const epcRatingClaim = {
    // uprn,
    verification: {
      trust_framework: "uk_pdtf",
      time: new Date().toISOString(),
      evidence
    },
    claims: {
      schema: currentSchemaId,
      path: "/propertyPack/materialFacts/propertyToBeSold/energyEfficiency",
      data: { energyEfficiencyRating: response.data }
    }
  };

  // return [epcCertificateClaim, epcRatingClaim];
  return [epcRatingClaim];
};

export default epcCollector2;
