import { db } from "../firebase";
import { currentSchemaId } from "../schemas/schemaUtils";

const titleExtentsCollector = async (currentState) => {
  // fetch the existing title numbers from the state
  const titleNumbers = currentState.propertyPack?.titlesToBeSold?.map(
    (title) => title.titleNumber
  );
  if (!titleNumbers) return null;
  // const uprn = currentState.uprn.toString();
  const titleResultPromises = titleNumbers.map((titleNo) => {
    return db.collection("titleRecords").doc(titleNo).get();
  });
  const titleDocs = await Promise.all(titleResultPromises);

  const evidence = [
    {
      type: "electronic_record",
      record: {
        type: "nps",
        source: {
          name: "HM Land Registry National Polygon Service"
        }
      }
    }
  ];

  // TODO assumes number and order of title numbers is immutable...
  const extentsClaims = titleDocs.map((result, index) => {
    return {
      // uprn,
      verification: {
        trust_framework: "uk_pdtf",
        time: new Date().toISOString(),
        evidence
      },
      claims: {
        schema: currentSchemaId,
        path: `/propertyPack/titlesToBeSold/${index}/titleExtents`,
        data: result.data().geometries.map((geometry) => JSON.parse(geometry)),
        attribution:
          "Information produced by HM Land Registry. © Crown copyright and database rights 2022"
      }
    };
  });
  console.log(extentsClaims);
  return extentsClaims;
};

export default titleExtentsCollector;
