import React from "react";

import { Button } from "@mui/material";
import { Add, ArrowUpward, ArrowDownward, Remove } from "@mui/icons-material";

const mappings = {
  remove: Remove,
  plus: Add,
  "arrow-up": ArrowUpward,
  "arrow-down": ArrowDownward
};

const IconButton = (props) => {
  const { icon, className, iconProps, ...otherProps } = props;
  const IconComp = mappings[icon];
  return (
    <Button {...otherProps} size="small">
      <IconComp {...iconProps} />
    </Button>
  );
};

export default IconButton;
