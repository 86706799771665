import React, { useState, useContext } from "react";
import { db, auth } from "../../firebase";
import { Link, useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
// import addNotification from "../../util/addNotification";
// import trackEvent from "../../util/trackEvent";

import {
  Button as MuiButton,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel as MuiFormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField as MuiTextField,
  Typography as MuiTypography
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import Logo from "../../assets/veyancer_wordandlogosolid28.png";
import userContext from "../../contexts/userContext";
import { LoginOrSignupWithGoogle } from "../../util/authMethods";

const Wrapper = styled(Paper)`
  padding: 20px 60px 8px;
`;
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(spacing);

export default function Signup() {
  const { setProfile } = useContext(userContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [termsConsent, setTermsConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();

  const handleSignup = () => {
    setLoading(true);
    let userData = {};
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        userData = {
          id: response.user.uid,
          email,
          firstName,
          lastName,
          termsConsent,
          marketingConsent,
          loginType: "Password"
        };
        return db.collection("users").doc(userData.id).set(userData);
      })
      .then((response) => {
        setProfile(userData);
        setLoading(false);
        history.push("/");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setError(error.message);
      });
  };

  const buttonDisabled = () => {
    return !(
      email &&
      /\S+@\S+\.\S+/.test(email) &&
      password &&
      firstName &&
      lastName &&
      termsConsent &&
      !loading
    );
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignContent="center"
      direction="column"
      spacing={6}>
      <Grid item>
        <Wrapper elevation={0}>
          <Container maxWidth="xs">
            <Helmet title="Signup to Veyancer" />
            <Grid
              container
              justifyContent="center"
              onClick={() => history.push("/")}>
              <img src={Logo} alt="Veyancer logo" />
            </Grid>
            <Typography
              component="h1"
              variant="h5"
              align="center"
              gutterBottom
              my={3}>
              Create an Account
            </Typography>
            <TextField
              mb={2}
              required
              autoFocus
              id="firstName"
              name="firstName"
              type="text"
              label="First name"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              fullWidth
            />{" "}
            <TextField
              mb={2}
              required
              id="lastName"
              name="lastName"
              type="text"
              label="Last name"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              fullWidth
            />
            <TextField
              mb={2}
              required
              id="email"
              name="email"
              type="email"
              label="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              fullWidth
            />
            <TextField
              mb={2}
              required
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              label="Choose a password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}>
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <FormControlLabel
              mt={3}
              // position checkbox at top
              style={{ display: "table" }}
              control={
                <div style={{ display: "table-cell" }}>
                  <Checkbox
                    checked={Boolean(termsConsent)}
                    onChange={(event) => setTermsConsent(event.target.checked)}
                    name="termsConsent"
                    color="primary"
                  />
                </div>
              }
              label={
                <Typography variant="body2" color="textSecondary">
                  I agree to Veyancers's{" "}
                  <a href="https://veyancer.com/tos"> Terms of Service</a> and{" "}
                  <a href="https://veyancer.com/privacy">Privacy Policy</a>, and
                  I consent to Veyancer transferring, hosting and processing my
                  data outside of the UK.*
                </Typography>
              }
              labelPlacement="end"
            />
            <FormControlLabel
              mb={3}
              // position checkbox at top
              style={{ display: "table" }}
              control={
                <div style={{ display: "table-cell" }}>
                  <Checkbox
                    checked={Boolean(marketingConsent)}
                    onChange={(event) =>
                      setMarketingConsent(event.target.checked)
                    }
                    name="marketingConsent"
                    color="primary"
                  />
                </div>
              }
              label={
                <Typography variant="body2" color="textSecondary">
                  I agree to receive marketing emails from Veyancer.
                </Typography>
              }
            />
            {error && (
              <Typography mb={2} variant="body2" color="primary">
                {error}
              </Typography>
            )}
            {/* <Grid container justify="flex-end">
                <Typography variant="body2" color="textSecondary">
                  * Required
                </Typography>
              </Grid> */}
            <Grid container justifyContent="center">
              <Button
                my={4}
                variant="contained"
                fullWidth
                onClick={handleSignup}
                color="primary"
                size="large"
                disabled={buttonDisabled()}>
                {loading ? <CircularProgress size={30} /> : "Let's get started"}
              </Button>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  Already have an account?
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  pb={1}
                  size="small"
                  component={Link}
                  to="/login"
                  color="secondary">
                  Login here
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Wrapper>
      </Grid>
      <Grid item container justifyContent="space-evenly" alignItems="center">
        <Grid item xs={5}>
          <Divider />
        </Grid>
        <Grid item xs={2}>
          <Typography
            variant="h6"
            style={{ color: "#afafaf", textAlign: "center" }}>
            or
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Divider />
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="center">
          <LoginOrSignupWithGoogle />
        </Grid>
      </Grid>
    </Grid>
  );
}
