import React, { useState, useEffect, useContext } from "react";
import { storage } from "../../firebase";
import { useParams, useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import SwipeableViews from "react-swipeable-views";
import Dropzone from "react-dropzone";
// import sha256 from "crypto-js/sha256";

import {
  Button as MuiButton,
  Typography,
  Stepper,
  Step,
  StepLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  Box,
  MobileStepper,
  LinearProgress,
  ListItemIcon,
  Link,
  IconButton,
  useScrollTrigger,
  Slide,
  AppBar,
  Toolbar
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  KeyboardArrowLeft,
  ArrowBack,
  KeyboardArrowRight,
  FilePresent,
  UploadFile,
  DeleteOutline as Delete,
  Check
} from "@mui/icons-material";
// import { useTheme } from "@mui/material/styles";

import Container from "common/Container";
import MuiForm from "../../components/MuiForm";
import userContext from "../../contexts/userContext";
import validatorContext from "../../contexts/validatorContext";
import { allTasks } from "../../schemas/tasks";
import useVerifiedClaims, {
  addVerifiedClaim
} from "../../util/useVerifiedClaims";

import { useCurrentSchema, subSchema } from "../../schemas/schemaUtils";

const jp = require("jsonpointer");

const Button = styled(MuiButton)(spacing);
const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};
const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};
const DropContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 20px 0;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

function FormTaskView() {
  const { uprn, encodedPath } = useParams();
  const path = decodeURIComponent(encodedPath);
  const task = allTasks.find((task) => task.path === path);
  const history = useHistory();
  const [taskData, setTaskData] = useState({});
  const [taskErrors, setTaskErrors] = useState([]);
  const [stepIndex, setStepIndex] = useState(0);

  const [stepSchemas, setStepSchemas] = useState({});
  const [stepPropertyNames, setStepPropertyNames] = useState({});
  // const [stepLabels, setStepLabels] = useState({});
  const [stepValidators, setStepValidators] = useState({});

  const [files, setFiles] = useState([]);
  const [fileHashes, setFileHashes] = useState({});
  const [uploads, setUploads] = useState({});

  const { validator } = useContext(validatorContext);
  const { profile } = useContext(userContext);
  // const theme = useTheme();
  const currentSchema = useCurrentSchema();
  const taskSchema = subSchema(currentSchema, path);
  let taskValidator = validator.getSchema(path);
  if (!taskValidator) {
    validator.addSchema(subSchema(currentSchema, path), path);
    taskValidator = validator.getSchema(path);
  }

  const [, currentState] = useVerifiedClaims(uprn);

  // Set up form data and step helpers
  useEffect(() => {
    if (!currentState) return;
    const existingData = jp.get(currentState, path);
    setTaskData(existingData || {});
    let newStepSchemas = {};
    let newStepPropertyNames = {};
    let newStepLabels = {};
    let newStepValidators = {};
    const properties = taskSchema.properties;
    if (!task.forceSingleStep) {
      Object.keys(properties).forEach((property, index) => {
        const propertyPath = "/" + property;
        newStepSchemas[index] = subSchema(taskSchema, propertyPath);
        newStepPropertyNames[index] = property;
        let label = property.replace(/([A-Z])/g, " $1");
        label = label.charAt(0).toUpperCase() + label.slice(1);
        newStepLabels[index] = label;
        if (!validator.getSchema(path + propertyPath)) {
          validator.addSchema(
            subSchema(currentSchema, path + propertyPath),
            path + propertyPath
          );
        }
        newStepValidators[index] = validator.getSchema(path + propertyPath);
      });
      setStepSchemas(newStepSchemas);
      setStepPropertyNames(newStepPropertyNames);
      // setStepLabels(newStepLabels);
      setStepValidators(newStepValidators);
    }
  }, [currentState, path]);

  useEffect(() => {
    // we manage a mirrored error state so we can enable/disable form submit
    if (taskValidator(taskData)) {
      setTaskErrors([]);
      return;
    }
    const filteredErrors = taskValidator.errors
      .filter((error) => !["enum", "oneOf"].includes(error.keyword))
      .map((error) => {
        return { ...error, message: "Please enter an answer" };
      });
    setTaskErrors(filteredErrors);
  }, [taskData, taskValidator]);

  const transformErrors = (errors) => {
    return errors
      .filter((error) => !["enum", "oneOf"].includes(error.name))
      .map((error) => {
        return { ...error, message: "" };
      });
  };

  // Load file list from storage
  useEffect(() => {
    if (!profile) return null;
    const listRef = storage.ref().child(`${profile.id}${path}`);
    const fileRefs = [];
    listRef
      .listAll()
      .then((res) =>
        res.items.map((itemRef) => {
          fileRefs.push(itemRef);
          return itemRef.getDownloadURL();
        })
      )
      .then((downloadURLPromises) =>
        Promise.all(downloadURLPromises).then((downloadURLs) => {
          const fileMap = {};
          downloadURLs.forEach((URL, index) => {
            fileMap[fileRefs[index].name] = {
              ref: fileRefs[index],
              downloadURL: URL
            };
          });
          setFiles(fileMap);
        })
      )
      .catch((err) => console.log(err));
  }, [uploads, path, profile]);

  const setStepDataFromForm = (formData) => {
    const propertyName = stepPropertyNames[stepIndex];
    let previousStepData = taskData[stepIndex];
    let currentTaskData = { ...taskData };
    currentTaskData[propertyName] = formData;
    setTaskData(currentTaskData);
    // auto-forward a previously null single field enum only
    const stepValidator = stepValidators[stepIndex](formData);
    const fieldProperties = Object.keys(formData);
    const firstFieldProperty = fieldProperties[0];
    const firstFieldIsEnum =
      typeof stepSchemas[stepIndex]?.enum !== undefined ||
      stepSchemas[stepIndex]?.properties[firstFieldProperty]?.enum !==
        undefined;
    if (
      typeof previousStepData === "undefined" &&
      fieldProperties.length === 1 &&
      firstFieldIsEnum &&
      stepValidator
    )
      //TODO animate selection for feedback?
      handleNext();
  };

  const handleNext = () => {
    if (stepIndex < Object.keys(stepSchemas).length - 1)
      setStepIndex(stepIndex + 1);
  };
  const handlePrevious = () => {
    if (stepIndex > 0) setStepIndex(stepIndex - 1);
  };

  const handleUpload = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    //TODO support multiple files (esp likely from pickers)
    //TODO use new name (add suffix) if name already exists?
    const storageRef = storage.ref();
    const fileRef = storageRef.child(`${profile.id}${path}/${file.name}`);
    const metadata = {
      contentType: file.type
    };
    const uploadTask = fileRef.put(file, metadata);
    const thisUpload = { state: "", progressPercent: 0 };
    let latestHash;
    setUploads({ ...uploads, [file.name]: thisUpload });
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const newStatus = {
          progressPercent:
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          state: snapshot.state
        };
        setUploads({ ...uploads, [file.name]: newStatus });
        console.log(
          snapshot.bytesTransferred,
          snapshot.totalBytes,
          snapshot.metadata
        );
        latestHash = snapshot.metadata.md5Hash;
      },
      (error) => {
        console.log(error);
      },
      () => {
        // Handle successful uploads on complete
        const { [file.name]: excluded, ...newUploads } = uploads;
        fileHashes[file.name] = latestHash;
        setFileHashes({ ...fileHashes });
        setUploads(newUploads);
      }
    );
  };

  const handleDeleteFile = (name) => {
    files[name].ref
      .delete()
      .then(() => {
        const { [name]: excluded, ...newFiles } = files;
        setFiles(newFiles);
      })
      .catch((error) => console.log(error));
  };

  const handleSubmit = async () => {
    const { firstName, lastName } = profile;
    let evidence = [
      {
        type: "vouch",
        verification_method: { type: "auth" },
        attestation: {
          type: "digital_attestation",
          voucher: {
            name: `${firstName} ${lastName}`
          }
        }
      }
    ];
    if (Object.keys(files).length > 0) {
      const attachments = Object.entries(files).map(([name, value]) => {
        console.log(name, value);
        return {
          desc: name,
          url: value.downloadURL,
          // TODO calculate digest on upload
          digest: { alg: "md5", value: fileHashes[name] }
        };
      });
      const docEvidence = {
        type: "document",
        attachments
      };
      evidence.push(docEvidence);
    }
    const verifiedClaim = {
      // uprn,
      // timestamp: new Date().getTime(),
      // ownerUserId: profile.id,
      verification: {
        trust_framework: "uk_pdtf",
        time: new Date().toISOString(),
        evidence
      },
      claims: {
        schema: currentSchema.$id,
        path,
        data: taskData
        // no attribution required
      }
    };
    console.log(verifiedClaim);
    await addVerifiedClaim(uprn, verifiedClaim);
    handleBack();
  };

  const handleBack = () => {
    history.push(`/properties/${uprn}`);
  };

  if (!task.forceSingleStep && Object.keys(stepSchemas).length < 1) return null;

  const numberOfSteps = Object.keys(stepSchemas).length - 1;
  console.log(taskSchema);
  return (
    <div>
      <HideOnScroll>
        <AppBar
          position={"fixed"}
          elevation={0}
          color="white"
          sx={{
            paddingTop: "env(safe-area-inset-top)",
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)"
            // backgroundColor: "white"
          }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              onClick={handleBack}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
              {task.name}
            </Typography>
            <IconButton
              size="large"
              edge="end"
              color="success"
              onClick={handleSubmit}>
              <Check />
            </IconButton>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Box height={{ xs: 64, sm: 81 }} />
      <Container>
        {numberOfSteps > 0 && taskSchema.description && (
          <Typography variant="h3" sx={{ mb: 2 }}>
            {taskSchema.description}
          </Typography>
        )}
        {/* <Typography variant="body1">{JSON.stringify(taskErrors)}</Typography> */}
        {numberOfSteps > 0 ? (
          <>
            <Box my={2} sx={{ display: { xs: "none", sm: "block" } }}>
              <Stepper activeStep={stepIndex} alternativeLabel nonLinear>
                {Object.keys(stepSchemas).map((schemaIndex) => (
                  <Step
                    key={schemaIndex}
                    completed={stepValidators[schemaIndex](
                      taskData[stepPropertyNames[schemaIndex]]
                    )}>
                    <StepLabel>{/* {stepLabels[schemaIndex]} */}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <SwipeableViews
              disableLazyLoading
              containerStyle={{
                transition:
                  "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s"
              }} // fix for https://github.com/oliviertassinari/react-swipeable-views/issues/599
              index={stepIndex}
              onChangeIndex={(index, indexLatest, meta) => setStepIndex(index)}
              resistance>
              {Object.keys(stepSchemas).map((index) => (
                <Container narrow key={index}>
                  <MuiForm
                    schema={stepSchemas[index]}
                    formData={taskData[stepPropertyNames[index]]}
                    onChange={({ formData }) =>
                      setStepDataFromForm(formData, index)
                    }
                    // onSubmit={handleSubmit}
                    transformErrors={transformErrors}
                    noHtml5Validate
                    showErrorList={false}
                    liveValidate>
                    <div></div> {/* to prevent automatic submit button */}
                  </MuiForm>
                </Container>
              ))}
            </SwipeableViews>
          </>
        ) : (
          <Container narrow my={2}>
            <MuiForm
              schema={taskSchema}
              formData={taskData}
              onChange={({ formData }) => setTaskData(formData)}
              // onSubmit={handleSubmit}
              transformErrors={transformErrors}
              noHtml5Validate
              showErrorList={false}
              liveValidate>
              <div></div> {/* to prevent automatic submit button */}
            </MuiForm>
          </Container>
        )}
        {numberOfSteps > 0 && (
          <>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-start">
                <Grid item>
                  {stepIndex !== 0 && (
                    <Button
                      m={2}
                      onClick={handlePrevious}
                      variant="outlined"
                      color="primary">
                      Prev
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {stepIndex !== numberOfSteps && (
                    <Button
                      m={2}
                      onClick={handleNext}
                      variant="outlined"
                      color="primary">
                      Next
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <MobileStepper
                variant="dots"
                position="static"
                steps={Object.keys(stepSchemas).length}
                activeStep={stepIndex}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={stepIndex === numberOfSteps}>
                    Next
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handlePrevious}
                    disabled={stepIndex === 0}>
                    <KeyboardArrowLeft />
                    Back
                  </Button>
                }
              />
            </Box>
          </>
        )}

        <Button mt={2} onClick={handleBack} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button
          mt={2}
          ml={2}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={taskErrors.length > 0}>
          Save
        </Button>

        {Object.keys(files).length > 0 && (
          <Box my={2}>
            <List dense>
              {Object.keys(files).map((name) => (
                <ListItem key={name}>
                  <ListItemIcon>
                    <FilePresent />
                  </ListItemIcon>
                  <ListItemText
                    primary={name}
                    secondary={
                      <Link href={files[name].downloadURL} target="_blank">
                        Download
                      </Link>
                    }
                  />
                  <ListItemSecondaryAction
                    onClick={() => handleDeleteFile(name)}>
                    <IconButton>
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {Object.keys(uploads).length > 0 && (
          <Box my={2}>
            <List dense>
              {Object.keys(uploads).map((name) => (
                <ListItem key={name}>
                  <ListItemIcon>
                    <UploadFile />
                  </ListItemIcon>
                  <ListItemText
                    primary={name}
                    secondary={
                      <LinearProgress
                        variant="determinate"
                        color="primary"
                        value={uploads[name].progressPercent}
                      />
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        <Dropzone
          onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}
          accept="image/jpeg,image/png,application/pdf ">
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject
          }) => (
            <DropContainer
              {...getRootProps({
                isDragActive,
                isDragAccept,
                isDragReject
              })}>
              <input {...getInputProps()} />
              <Typography variant="h6">
                Drag and drop a supporting document here, or click to select a
                file.
              </Typography>{" "}
              <Typography variant="body1">
                Images in JPG or PNG format, or PDF files, are accepted.
              </Typography>
            </DropContainer>
          )}
        </Dropzone>
      </Container>
    </div>
  );
}

export default FormTaskView;
