import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import styled from "@emotion/styled";

import {
  Typography,
  List,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";

import AddSharerDialog from "./AddSharerDialog";
import Loader from "./Loader";
import UserAvatar from "./UserAvatar";

const ListItem = styled(MuiListItem)`
  border: 1px solid LightGrey;
  margin-bottom: 8px;
  padding: 8px 8px;
  background: WhiteSmoke;
  border-radius: 5px;
  cursor: pointer;
`;

function SharingView({ profile, uprn }) {
  const [sharingUsers, setSharingUsers] = useState([]);
  const [loaded, setLoaded] = useState();

  useEffect(() => {
    const { sharingUserIds } = profile.myProperties[uprn];
    if (sharingUserIds && sharingUserIds.length > 0) {
      db.collection("users")
        .where("id", "in", sharingUserIds)
        .get() // only gets first 10
        .then((snapshot) => {
          return snapshot.docs.map((doc) => doc.data());
        })
        .then((results) => {
          setSharingUsers(results);
          setLoaded(true);
        })
        .catch((err) => console.log(err));
    } else {
      setLoaded(true);
    }
  }, [profile, uprn]);

  if (!loaded) return <Loader />;
  return (
    <>
      <Typography variant="h5">Sharing</Typography>
      {sharingUsers.length === 0 ? (
        <Typography>Not shared with anyone</Typography>
      ) : (
        <List>
          {sharingUsers.map((user) => (
            <ListItem key={user.id}>
              <ListItemIcon>
                <UserAvatar user={user} />
              </ListItemIcon>
              <ListItemText
                primary={user.firstName + " " + user.lastName}
                secondary={user.email}
              />
            </ListItem>
          ))}
        </List>
      )}
      <AddSharerDialog profile={profile} uprn={uprn} />
    </>
  );
}

export default SharingView;
