import React, { useState, useContext } from "react";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { Camera } from "@capacitor/camera";

import { db } from "../../firebase";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import VerifyButton from "@passbase/button/react";

import {
  Grid,
  Paper,
  Button,
  Typography as MuiTypography
} from "@mui/material";

import Logo from "../../assets/veyancer_wordandlogosolid28.png";
import userContext from "../../contexts/userContext";

const Wrapper = styled(Paper)`
  padding: 20px 60px 8px;
  // height: 100vh;
`;
const Typography = styled(MuiTypography)(spacing);

const errorDecode = {
  CANCELLED_BY_USER: "you cancelled the verification",
  BIOMETRIC_AUTHENTICATION_FAILED: "The biometric authentication failed",
  UNSUPPORTED_BROWSER: "your browser is not supported"
};

export default function Verify() {
  const { profile } = useContext(userContext);
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState();

  const history = useHistory();

  const handleStart = () => {
    console.log("starting");
    setInProgress(true);
    setError(null);
  };

  const handleError = (errorCode, context) => {
    console.log("Verification error " + errorCode);
    console.log("Verification error context " + context.StepID);
    setError(`An error occurred because ${errorDecode[errorCode]}`);
    setInProgress(false);
  };

  const handleSubmitted = async (identityAccessKey) => {
    console.log("Submtted with key " + identityAccessKey);
    await db
      .collection("users")
      .doc(profile.id)
      .update({ identityAccessKey, IdentifyVerificationStatus: "SUBMITTED" });
    setInProgress(false);
  };

  const handleFinish = async (identityAccessKey) => {
    console.log("Finished with key " + identityAccessKey);
    await db
      .collection("users")
      .doc(profile.id)
      .update({ identityAccessKey, IdentifyVerificationStatus: "PROCESSING" });
    setInProgress(false);
  };

  const handleNativeStart = async () => {
    await Browser.open({
      url: `https://verify.passbase.com/veyancer/?p=${objJsonB64}`
    });
  };

  const cameraPermissions = async () => {
    Camera.checkPermissions().then((result) => {
      console.log(JSON.stringify(result));
      return result;
    });
  };

  if (!profile) return null;
  const prefillAttributes = { email: profile.email, country: "gb" };
  const objJsonStr = JSON.stringify(prefillAttributes);
  const objJsonB64 = Buffer.from(objJsonStr).toString("base64");

  return (
    <Wrapper elevation={0}>
      <Helmet title="Verify my identity" />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        direction="column"
        spacing={1}>
        <Grid item onClick={() => history.push("/")}>
          <img src={Logo} alt="Veyancer logo" />
        </Grid>
        <Grid item>
          <Typography variant="h6" align="center" gutterBottom my={3}>
            Verify your identity
          </Typography>
        </Grid>
        <Grid item>
          {inProgress ? (
            <Typography variant="body1" align="center" gutterBottom my={3}>
              In progress...
            </Typography>
          ) : (
            <>
              <Typography variant="body1" align="center" gutterBottom my={3}>
                To help prevent fraud, we need to verify your identity. You'll
                need your UK Driving Licence or Passport to hand, but it
                shouldn't take more than a minute.
              </Typography>
              <Typography>
                Once you're done, we'll notify you when the verification is
                complete, but you can carry on using Veyancer in the meantime.
              </Typography>
            </>
          )}
          {error && (
            <Typography mb={2} variant="body2" color="error">
              {error}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <VerifyButton
            apiKey="D0QY0vP1Mm9iiwnviwiv335gQKS20JIJNAFVoZhIEtwIwlofk9LrG0XvjJr16tFl"
            prefillAttributes={prefillAttributes} //todo use user.email safer?
            onStart={handleStart}
            onError={(errorCode, context) => handleError(errorCode, context)}
            onSubmitted={(identityAccessKey) =>
              handleSubmitted(identityAccessKey)
            }
            onFinish={(identityAccessKey) => handleFinish(identityAccessKey)}
          />
        </Grid>
        {Capacitor.isNativePlatform() && (
          <>
            <Grid item>
              <Button variant="contained" onClick={handleNativeStart}>
                Start native browser verification
              </Button>
            </Grid>
            <Grid item>
              <Typography>{cameraPermissions()}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Wrapper>
  );
}
