import React from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from "@emotion/styled";

import {
  Typography,
  Button,
  List,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Grid,
  Icon,
  Link
} from "@mui/material";

import { Delete } from "@mui/icons-material";

import Container from "common/Container";

import uiSchema from "../../schemas/uiSchema.json";
import DataView from "../../components/DataView";
import {
  plainTextForClaim,
  IconForClaim
} from "../../components/NavIconForClaims";
import useVerifiedClaims, { deleteClaim } from "../../util/useVerifiedClaims";

const jp = require("jsonpointer");

const ListItem = styled(MuiListItem)`
  border: 1px solid LightGrey;
  margin-bottom: 8px;
  padding: 12px 8px;
  background: WhiteSmoke;
  border-radius: 5px;
`;

function ClaimsView() {
  const { uprn, encodedPath } = useParams();
  const path = decodeURIComponent(encodedPath);
  const history = useHistory();

  const [verifiedClaims, , currentClaimsMap, loadingVerifiedClaims] =
    useVerifiedClaims(uprn);

  const handleBack = () => {
    history.push(`/properties/${uprn}`);
  };

  const titles = jp.get(uiSchema, path);
  const pathClaims = jp.get(currentClaimsMap, path);
  console.log(pathClaims);

  if (loadingVerifiedClaims || !verifiedClaims || !pathClaims) return null;

  return (
    <Container>
      <Button mb={2} variant="text" onClick={handleBack}>
        {"<- "} Back
      </Button>
      <Typography variant="h5">{titles.title}</Typography>
      <List>
        {pathClaims.map((claim) => (
          <ListItem key={claim.timestamp}>
            <ListItemIcon>
              <Icon sx={{ marginLeft: "8px" }}>{IconForClaim(claim)}</Icon>
            </ListItemIcon>
            <Grid container direction="column">
              <Grid item>
                <DataView
                  data={JSON.parse(claim.data)}
                  titles={titles}
                  level={4}
                />
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {plainTextForClaim(claim)}
                </Typography>
                <Typography variant="body2">{claim.attributions}</Typography>
              </Grid>
              {claim.provenance.level === "Documented" &&
                claim.provenance.documents.map((document) => (
                  <Link
                    key={document.URI}
                    href={document.URI}
                    target="_blank"
                    rel="noopener">
                    {document.name}
                  </Link>
                ))}
              {claim.provenance.level === "VerifiableDigital" && (
                <Link
                  href={claim.provenance.verificationUri}
                  target="_blank"
                  rel="noopener">
                  {claim.provenance.verificationUri}
                </Link>
              )}
            </Grid>
            <ListItemSecondaryAction>
              <ListItemIcon onClick={() => deleteClaim(claim.id)}>
                <Delete />
              </ListItemIcon>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default ClaimsView;
