import React from "react";
import { Typography, List, ListItem as MuiListItem } from "@mui/material";
import styled from "@emotion/styled";
import NavIconForClaims from "./NavIconForClaims";

const ListItem = styled(MuiListItem)`
  border: 1px solid LightGrey;
  padding: 0px 8px;
`;

function DataView({ data, titles, claims, level = 1 }) {
  if (typeof titles?.title === undefined) return null;
  const renderClaim = Array.isArray(claims) ? (
    <NavIconForClaims claims={claims} />
  ) : null; // doesn't support nested claims
  if (["number", "string"].includes(typeof data)) {
    return (
      <Typography>
        {titles.title} {data.toString()} {renderClaim}
      </Typography>
    );
  } else if (typeof data === "boolean") {
    return (
      <Typography>
        {titles.title} {data ? "Yes" : "No"} {renderClaim}
      </Typography>
    );
  } else if (Array.isArray(data)) {
    return (
      <List py={0} ml={4}>
        <Typography variant={"h" + (level + 3)}>
          {titles.title} {renderClaim}
        </Typography>
        {data.map((item) => (
          <ListItem key={item} ml={4}>
            <DataView
              data={item}
              titles={titles.items}
              claims={claims?.items}
              level={level + 1}
            />
          </ListItem>
        ))}
      </List>
    );
  } else {
    // use the predictable titles ordering, not the order properties were originally added
    return (
      <List mpy={0} ml={4}>
        <Typography variant={"h" + (level + 3)}>
          {titles.title} {renderClaim}
        </Typography>
        {Object.keys(titles).map((property) => {
          if (property === "title" || !data || !data[property]) return null;
          return (
            <ListItem key={property}>
              <DataView
                data={data[property]}
                titles={titles[property]}
                claims={claims?.[property]}
                level={level + 1}
              />
            </ListItem>
          );
        })}
      </List>
    );
  }
}

export default DataView;
