export const sampleProperty = {
  administrative_county: "",
  building_name: "",
  building_number: "10",
  country: "England",
  county: "Avon",
  delivery_point_suffix: "1B",
  department_name: "",
  dependant_locality: "",
  dependant_thoroughfare: "",
  district: "Bristol, City of",
  double_dependant_locality: "",
  eastings: 359921,
  latitude: 51.4857232,
  line_1: "10 Downend Park",
  line_2: "",
  line_3: "",
  longitude: -2.578599,
  northings: 176467,
  organisation_name: "",
  po_box: "",
  post_town: "BRISTOL",
  postal_county: "Avon",
  postcode: "BS7 9PU",
  postcode_inward: "9PU",
  postcode_outward: "BS7",
  postcode_type: "S",
  premise: "10",
  su_organisation_indicator: "",
  sub_building_name: "",
  thoroughfare: "Downend Park",
  traditional_county: "Gloucestershire",
  udprn: 2772954,
  umprn: "",
  uprn: "35797",
  ward: "Lockleaze"
};
