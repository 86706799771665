import React, { useContext, useEffect, useState, useCallback } from "react";
import { db } from "../../firebase";
import { useParams, useHistory, Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useDocumentData } from "react-firebase-hooks/firestore";

import {
  useScrollTrigger,
  AppBar,
  Box,
  Slide,
  Container,
  Typography,
  Button,
  List,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Paper
} from "@mui/material";
import {
  CircleOutlined,
  TaskAlt,
  ChevronRight,
  KeyboardArrowLeft as BackIcon
} from "@mui/icons-material";

import { useCurrentSchema, subSchema } from "../../schemas/schemaUtils";
import { tasksForRole } from "../../schemas/tasks";
// import availableCollectors from "../../collectors/availableCollectors";
import useVerifiedClaims from "../../util/useVerifiedClaims";
import { ShortTypographyForClaims } from "../../components/NavIconForClaims";

import Loader from "../../components/Loader";
import userContext from "../../contexts/userContext";
import validatorContext from "../../contexts/validatorContext";

import GeometryView from "../../components/GeometryView";

const jp = require("jsonpointer");
const googleMapsApiKey = "AIzaSyDSz-BxsKrAKOzwiHUGbWlufToWTWdqcnI";

const ListItem = styled(MuiListItem)`
  cursor: pointer;
`;
const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export default function PropertyView() {
  const { profile, updateProfile } = useContext(userContext);
  const { validator } = useContext(validatorContext);
  const { uprn } = useParams();
  const history = useHistory();
  const [streetViewQuery, setStreetViewQuery] = useState();
  const [imageHeight, setImageHeight] = useState();
  const [imageWidth, setImageWidth] = useState();
  const [taskErrors, setTaskErrors] = useState([]);
  const [errors, setErrors] = useState([]);
  const currentSchema = useCurrentSchema();

  const [property, loadingProperty] = useDocumentData(
    db.collection("properties").doc(uprn)
  );
  const [, currentState, currentClaimsMap, loadingVerifiedClaims] =
    useVerifiedClaims(uprn);

  useEffect(() => {
    if (!property || !imageHeight || !imageWidth) return;
    // console.log(imageHeight, imageWidth);
    setStreetViewQuery(
      `https://maps.googleapis.com/maps/api/streetview?size=${imageWidth}x${imageHeight}&key=${googleMapsApiKey}&location=${property.latitude},${property.longitude}`
    );
    // setMapViewQuery(
    //   `https://maps.googleapis.com/maps/api/staticmap?size=${imageWidth - 48}x${
    //     imageWidth - 48
    //   }&maptype=hybrid&zoom=19&style=feature:poi|visibility:off&key=${googleMapsApiKey}&markers=color:blue|"${
    //     property.latitude
    //   },${property.longitude}"`
    // );
  }, [property, imageHeight, imageWidth]);

  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const headingImageRef = useCallback((node) => {
    if (node !== null) {
      const width = node.getBoundingClientRect().width;
      // console.log(width);
      setImageWidth(width);
      let height = Math.round(width * 0.75);
      if (height > 300) height = 300;
      setImageHeight(height);
    }
  }, []);

  useEffect(() => {
    if (!currentState || !profile) return;
    const rootValidator = validator.getSchema(currentSchema.$id);
    setErrors(rootValidator(currentState) ? [] : rootValidator.errors);
    if (!profile.myProperties?.[uprn]) return;
    const myRole = profile.myProperties[uprn].role;
    const tasks = tasksForRole(myRole);
    if (!tasks || tasks.length < 1) return;
    let newTaskErrors = {};
    tasks.forEach((task) => {
      const sub = subSchema(currentSchema, task.path);
      const data = jp.get(currentState, task.path);
      let validateTask = validator.getSchema(task.path);
      if (!validateTask) {
        validator.addSchema(sub, task.path);
        validateTask = validator.getSchema(task.path);
      }
      validateTask(data || {}); // otherwise we get a single error for 'no object'
      newTaskErrors[task.path] = validateTask.errors || [];
      // }
    });
    setTaskErrors(newTaskErrors);

    // adding currentSchema as dependency causes recursive loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSchema.$id, validator, profile, currentState]);

  const missingPropertyPaths = errors
    .filter(({ params }) => params.missingProperty)
    .map((error) => `$${error.dataPath}.${error.params.missingProperty}`);

  // const collectors = availableCollectors(currentState);

  const TypographyForTask = ({ task }) => {
    const errorNumber = taskErrors[task.path]?.length;
    if (errorNumber > 1)
      return (
        <Typography variant="subtitle">
          {errorNumber + " questions remaining"}
        </Typography>
      );
    if (errorNumber > 0)
      return <Typography variant="subtitle">1 question remaining</Typography>;
    if (missingPropertyPaths.includes(task.path))
      return <Typography variant="subtitle">Answers required</Typography>;
    const taskClaims = jp.get(currentClaimsMap, task.path);
    if (!taskClaims) return "OOoopos";
    // console.log(taskClaims);
    return ShortTypographyForClaims(taskClaims);
  };

  const handleAmSeller = async () => {
    let myProperties = profile.myProperties || {};
    myProperties[property.uprn] = { role: "Seller" };
    updateProfile({ ...profile, myProperties });
    console.log("added as seller");
  };
  if (loadingProperty || loadingVerifiedClaims) return <Loader />;

  return (
    <Container
      maxWidth="md"
      sx={{
        paddingTop: "env(safe-area-inset-top)",
        paddingLeft: "env(safe-area-inset-left)",
        paddingRight: "env(safe-area-inset-right)"
      }}>
      <HideOnScroll>
        <AppBar
          sx={{ backgroundColor: "transparent" }}
          position={"fixed"}
          elevation={0}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}>
            <IconButton
              size="large"
              color="primary"
              onClick={() => history.push("/")}
              sx={{ margin: 2, backgroundColor: "#fff" }}
              component={Link}
              to={"/"}>
              <BackIcon />
            </IconButton>
          </Box>
        </AppBar>
      </HideOnScroll>
      <Box
        style={{
          backgroundImage: `url(${streetViewQuery})`,
          backgroundRepeat: "no-repeat",
          // backgroundAttachment: "fixed",
          backgroundOrigin: "content-box",
          height: imageHeight,
          width: imageWidth
        }}>
        <Box ref={headingImageRef}>
          {/* <img src={mapQuery} alt="property"></img> */}
        </Box>
        <Box height={imageHeight} width={imageWidth}></Box>
        <Paper
          elevation={0}
          style={{
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            marginTop: "-24px"
          }}>
          <Box p={3}>
            <Typography variant="h2">{property.line_1}</Typography>
            <Typography variant="subtitle">
              {property.line_2} {property.line_3} {property.post_town},{" "}
              {property.postcode}
            </Typography>
          </Box>
          {window.location.hostname === "localhost" && (
            <Button
              component={Link}
              to={`/properties/dev/${property.uprn}`}
              variant="text">
              Dev View
            </Button>
          )}
          <Box sx={{ m: 2, borderRadius: "16px" }}>
            <GeometryView
              currentState={currentState}
              height={imageHeight}
              property={property}
            />
          </Box>
          {profile?.myProperties?.[uprn] && (
            <List>
              {tasksForRole(profile.myProperties[uprn].role).map((task) => (
                <ListItem
                  key={task.path}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    history.push(
                      `/properties/${uprn}/tasks/form/${encodeURIComponent(
                        task.path
                      )}`
                    )
                  }
                  divider>
                  <ListItemIcon sx={{ color: "success.main" }}>
                    {taskErrors[task.path]?.length === 0 &&
                    !missingPropertyPaths.includes(task.path) ? (
                      <TaskAlt />
                    ) : (
                      <CircleOutlined />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography variant="h3">{task.name}</Typography>}
                    secondary={<TypographyForTask task={task} />}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="select">
                      <ChevronRight />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
          {profile && !profile.myProperties?.[uprn] && (
            <Button variant="outlined" onClick={handleAmSeller}>
              I'm the Seller
            </Button>
          )}
        </Paper>
      </Box>
      <Box height={{ xs: 56, sm: 64 }} />
    </Container>
  );
}
