import React from "react";

import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";

const AddButton = (props) => (
  <Button {...props} color="secondary">
    <Add /> Add Item
  </Button>
);

export default AddButton;
