import { useContext } from "react";
import { firebase, auth, db } from "../firebase";
import userContext from "../contexts/userContext";
import { useHistory } from "react-router-dom";
import GoogleButton from "react-google-button";

export function LoginOrSignupWithGoogle() {
  const { setProfile } = useContext(userContext); // can also signup from here
  const history = useHistory();

  const doLogin = () => {
    let userData = {};
    const provider = new firebase.auth.GoogleAuthProvider();
    auth
      .signInWithPopup(provider)
      .then((response) => {
        console.log("REs goo ", response);
        if (response.additionalUserInfo.isNewUser) {
          console.log("in new user");

          userData = {
            id: response.user.uid,
            email: response.user.email,
            displayName: response.user.displayName,
            photoURL: response.user.photoURL,
            loginType: "Google"
          };
          return db
            .collection("users")
            .doc(userData.id)
            .set(userData)
            .then((res) => setProfile(userData));
        } else {
          console.log("in existing user " + response.user);
          return db
            .collection("users")
            .doc(response.user.uid)
            .get()
            .then((doc) => setProfile(doc.data()));
        }
      })
      .then((res) => {
        console.log("in pre push ", res);
        history.push("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <GoogleButton onClick={doLogin} type="light" label="Continue with Google" />
  );
}
