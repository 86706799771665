import React from "react";
import styled from "@emotion/styled";

const Root = styled.div`
  padding-top: 30px;
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function AuthLayout({ children }) {
  return <Root>{children}</Root>;
}

export default AuthLayout;
