import React from "react";

import { useScrollTrigger, AppBar, Box, Slide, Container } from "@mui/material";

import Topbar from "../components/Topbar";
// import Footer from "./Main/components/Footer";

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const Plain = ({
  children,
  themeToggler,
  themeMode,
  setThemePalette,
  paletteType
}) => {
  return (
    <Container
      maxWidth="md"
      sx={{
        paddingTop: "env(safe-area-inset-top)",
        paddingLeft: "env(safe-area-inset-left)",
        paddingRight: "env(safe-area-inset-right)"
      }}>
      <HideOnScroll>
        <AppBar
          sx={{ backgroundColor: "transparent" }}
          position={"fixed"}
          elevation={0}>
          <Topbar
            themeMode={themeMode}
            themeToggler={themeToggler}
            setThemePalette={setThemePalette}
            paletteType={paletteType}
          />
        </AppBar>
      </HideOnScroll>
      <main>
        {children}
        <Box height={{ xs: 56, sm: 64 }} />
      </main>
    </Container>
  );
};

export default Plain;
