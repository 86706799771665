import React from "react";
import { Avatar, Typography } from "@mui/material";

import stc from "string-to-color";
import initials from "../util/initials";

function UserAvatar({ user, size }) {
  if (!user) return null;
  return (
    <Avatar
      sx={{ height: size || 30, width: size || 30 }}
      alt={user.name}
      src={user.photo}
      style={{
        backgroundColor: stc(user.id)
      }}>
      <Typography variant="body2">{initials(user)}</Typography>
    </Avatar>
  );
}

export default UserAvatar;
