import Form from "@rjsf/material-ui";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Checkbox,
  TextField
} from "@mui/material";

import FieldTemplate from "./FieldTemplate";
import ObjectFieldTemplate from "./ObjectFieldTemplate";

function schemaRequiresTrueValue(schema) {
  // Check if const is a truthy value
  if (schema.const) {
    return true;
  }
  if (schema.enum && schema.enum.length === 1 && schema.enum[0] === true) {
    return true;
  }
  if (schema.anyOf && schema.anyOf.length === 1) {
    return schemaRequiresTrueValue(schema.anyOf[0]);
  }
  if (schema.oneOf && schema.oneOf.length === 1) {
    return schemaRequiresTrueValue(schema.oneOf[0]);
  }
  if (schema.allOf) {
    return schema.allOf.some(schemaRequiresTrueValue);
  }
  return false;
}

const TitleField = ({ title }) => <Typography variant="h4">{title}</Typography>;

const DescriptionField = ({ description }) => (
  <Typography variant="body1">{description}</Typography>
);

const CheckboxWidget = ({
  schema,
  id,
  value,
  disabled,
  readonly,
  label,
  autofocus,
  onChange
}) => {
  // Because an unchecked checkbox will cause html5 validation to fail, only add
  // the "required" attribute if the field value must be "true", due to the
  // "const" or "enum" keywords
  const required = schemaRequiresTrueValue(schema);
  console.log(value);
  value = typeof value === "object" ? false : value;
  console.log(value);
  return (
    <>
      <Typography variant="body1">{schema.description}</Typography>
      <FormControlLabel
        control={
          <Checkbox
            id={id}
            checked={value}
            required={required}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            onChange={(event) => onChange(event.target.checked)}
          />
        }
        label={label || schema.title}
      />
    </>
  );
};

const SelectWidget = function ({
  schema,
  id,
  options,
  label,
  required,
  disabled,
  readonly,
  value,
  onChange,
  rawErrors = []
}) {
  value = typeof value === "undefined" ? null : value;
  return (
    <FormControl
      // required={required}
      component="fieldset"
      error={rawErrors.length > 0}>
      {schema.title !== "" && (
        <FormLabel component="legend">{label || schema.title}</FormLabel>
      )}
      <RadioGroup
        row
        aria-label={label}
        name={id}
        value={value}
        onChange={(event) => onChange(event.target.value)}>
        {options.enumOptions.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
            disabled={disabled || readonly}
          />
        ))}
      </RadioGroup>
      {/* <FormHelperText>
        {rawErrors.length > 0 && "Choose an option"}
      </FormHelperText> */}
    </FormControl>
  );
};

const TextWidget = ({
  id,
  placeholder,
  required,
  readonly,
  disabled,
  type,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  options,
  schema,
  uiSchema,
  rawErrors = [],
  formContext,
  registry // pull out the registry so it doesn't end up in the textFieldProps
}) => {
  const onChangeThis = (event) => {
    const { value } = event.target;
    onChange(value === "" ? null : value);
  };
  return (
    <TextField
      variant="outlined"
      multiline
      minRows={1}
      id={id}
      placeholder={placeholder}
      label={label || schema.title}
      autoFocus={autofocus}
      // required={required}
      disabled={disabled || readonly}
      type="string"
      value={value || value === 0 ? value : ""}
      error={rawErrors.length > 0}
      onChange={onChangeThis}
      //   helperText={rawErrors.length > 0 ? "Required" : ""}
    />
  );
};

const widgets = {
  SelectWidget,
  CheckboxWidget,
  TextWidget
};

const fields = {
  TitleField,
  DescriptionField
};

export default function MuiForm(props) {
  return (
    <Form
      tag="div"
      fields={fields}
      widgets={widgets}
      ObjectFieldTemplate={ObjectFieldTemplate}
      FieldTemplate={FieldTemplate}
      {...props}
    />
  );
}
