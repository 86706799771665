import { db, functions } from "../firebase";
import { currentSchemaId } from "../schemas/schemaUtils";

const extractCallable = functions.httpsCallable("hmlr-officialCopyWithSummary");

const registerExtractCollector = async (currentState) => {
  // fetch the existing title numbers from the state
  const titleNumbers = currentState.propertyPack?.titlesToBeSold?.map(
    (title) => title.titleNumber
  );
  if (!titleNumbers) return null;
  // const uprn = currentState.uprn.toString();
  const titleResponses = await Promise.all(
    titleNumbers.map(async (titleNo) => {
      console.log("looking for", titleNo);
      const existingExtractDoc = await db
        .collection("registerExtracts")
        .doc(titleNo)
        .get();
      console.log(existingExtractDoc);
      if (existingExtractDoc.exists) return existingExtractDoc.data();
      console.log("no record for", titleNo);
      console.log("gonna call for it!");
      const response = await extractCallable({ titleNo });
      console.log("got response!");
      console.log(response);
      const result = response.data.Results;
      await db.collection("registerExtracts").doc(titleNo).set(result);
      return result;
    })
  );

  console.log(titleResponses);
  const titleResults = titleResponses.map((response) => {
    const { Attachment, OCSummaryData, OCRegisterData } = response;
    return { Attachment, OCSummaryData, OCRegisterData };
  });

  const evidence = [
    {
      type: "electronic_record",
      record: {
        type: "hmlr",
        source: {
          name: "HM Land Registry"
        }
      }
    }
  ];

  const extractClaims = titleResults.map((result, index) => {
    return {
      // uprn,
      verification: {
        trust_framework: "uk_pdtf",
        time: new Date().toISOString(),
        evidence
      },
      claims: {
        schema: currentSchemaId,
        path: `/propertyPack/titlesToBeSold/${index}/registerExtract`,
        data: result,
        attribution:
          "Information produced by HM Land Registry. © Crown copyright and database rights 2022"
      }
    };
  });
  console.log(extractClaims);
  // TODO also add claims for freehold, legal owners, sellers capacity
  return extractClaims;
};

export default registerExtractCollector;
