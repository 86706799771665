import React, { useState } from "react";
import { db, auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { BrowserRouter as Router } from "react-router-dom";

import { UserProvider } from "./contexts/userContext";
import { ValidatorProvider } from "./contexts/validatorContext";
import Ajv from "ajv";

import Routes from "./Routes";

import GeoJSON from "./schemas/pdtf/GeoJSON.json";
import schema1_0_0 from "./schemas/pdtf/pdtf-1.0.0";
import schema1_0_1 from "./schemas/pdtf/pdtf-1.0.1";
import property_pack_1_0_2 from "./schemas/pdtf/property-pack-1.0.2";
import baspi_a_1_0_0 from "./schemas/pdtf/baspi-a-material-facts-1.0.0.json";
import baspi_b_1_0_0 from "./schemas/pdtf/baspi-b-legal-information-1.0.0.json";
import title_deed_0_1_0 from "./schemas/pdtf/title-deed-0.1.0";
import epc0_1_0 from "./schemas/pdtf/energy-performance-certificate-0.1.0";
// import verified_claims_12 from "./schemas/pdtf/verified_claims-12";

export default function App() {
  const [user, loading] = useAuthState(auth);
  const [profile, setProfile] = useState();

  const updateProfile = (newProfile) => {
    db.collection("users")
      .doc(user.uid)
      .update(newProfile)
      .then(() => setProfile(newProfile));
  };

  if (user && !profile) {
    db.collection("users")
      .doc(user.uid)
      .get()
      .then((doc) => {
        setProfile(doc.data());
      });
  }
  if (!user && profile) {
    setProfile(null);
  }
  const validator = new Ajv({ allErrors: true });
  validator.addSchema(GeoJSON);
  validator.addSchema(schema1_0_0);
  validator.addSchema(schema1_0_1);
  validator.addSchema(property_pack_1_0_2);
  validator.addSchema(baspi_a_1_0_0);
  validator.addSchema(baspi_b_1_0_0);
  validator.addSchema(title_deed_0_1_0);
  validator.addSchema(epc0_1_0);
  // validator.addSchema(verified_claims_12);

  // console.log("App_ user", user);
  // console.log("App_ profile ", profile);

  return (
    <ValidatorProvider value={{ validator }}>
      <UserProvider
        value={{ user, loading, profile, setProfile, updateProfile }}>
        <Router>
          <Routes />
        </Router>
      </UserProvider>
    </ValidatorProvider>
  );
}
