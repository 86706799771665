import React, { useContext, useState, useEffect } from "react";
import { db } from "../../firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { useParams, useHistory, Link } from "react-router-dom";
import styled from "@emotion/styled";
import ReactJson from "react-json-view";

import {
  Container,
  Typography,
  Button,
  List,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Box,
  Chip,
  Tabs,
  Grid,
  ListItemSecondaryAction,
  Paper,
  BottomNavigation,
  BottomNavigationAction
} from "@mui/material";
import {
  CircleOutlined,
  CloudDownload,
  TaskAlt,
  PlaylistAddCheck,
  HomeWork,
  Inbox,
  People
} from "@mui/icons-material";

import NavIconForClaims from "../../components/NavIconForClaims";

import TasksRemainingCircle from "../../components/TasksRemainingCircle";
import PropertyAvatar from "../../components/PropertyAvatar";
import Loader from "../../components/Loader";
import userContext from "../../contexts/userContext";
import validatorContext from "../../contexts/validatorContext";

import { useCurrentSchema, subSchema } from "../../schemas/schemaUtils";
import { tasksForRole } from "../../schemas/tasks";
import availableCollectors from "../../collectors/availableCollectors";
import uiSchema from "../../schemas/uiSchema.json";
import useVerifiedClaims, {
  addVerifiedClaim,
  deleteClaimsForUprn
} from "../../util/useVerifiedClaims";
import DataView from "../../components/DataView";
import SharingView from "../../components/SharingView";
import GeometryView from "../../components/GeometryView";
import StreetView from "../../components/StreetView";

const jp = require("jsonpointer");

const ListItem = styled(MuiListItem)`
  border: 1px solid LightGrey;
  margin-bottom: 8px;
  padding: 8px 8px;
  background: WhiteSmoke;
  border-radius: 5px;
  cursor: pointer;
`;

const ReactJsonView = (props) => (
  <ReactJson style={{ overflowWrap: "anywhere" }} {...props} />
);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
};

export default function DevView() {
  const { profile } = useContext(userContext);
  const { validator } = useContext(validatorContext);
  const currentSchema = useCurrentSchema();

  const { uprn } = useParams();
  const [taskErrors, setTaskErrors] = useState([]);
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const [errors, setErrors] = useState([]);

  const [property, loadingProperty] = useDocumentData(
    db.collection("properties").doc(uprn)
  );
  const [
    verifiedClaims,
    currentState,
    currentClaimsMap,
    loadingVerifiedClaims
  ] = useVerifiedClaims(uprn);

  useEffect(() => {
    if (!currentState || !profile) return;
    if (!profile.myProperties[uprn]) return;
    const rootValidator = validator.getSchema(currentSchema.$id);
    setErrors(rootValidator(currentState) ? [] : rootValidator.errors);
    const myRole = profile.myProperties[uprn].role;
    const tasks = tasksForRole(myRole);
    if (!tasks || tasks.length < 1) return;
    let newTaskErrors = {};
    tasks.forEach((task) => {
      const sub = subSchema(currentSchema, task.path);
      const data = jp.get(currentState, task.path);
      let validateTask = validator.getSchema(task.path);
      if (!validateTask) {
        validator.addSchema(sub, task.path);
        validateTask = validator.getSchema(task.path);
      }
      validateTask(data || {}); // otherwise we get a single error for 'no object'
      // console.log(validateTask.errors);
      newTaskErrors[task.path] = validateTask.errors || [];
      // }
    });
    setTaskErrors(newTaskErrors);
    // adding currentSchema as dependency causes recursive loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSchema.$id, validator, profile, currentState]);

  if (loadingVerifiedClaims || loadingProperty || !profile) return <Loader />;

  const handleCollect = async (collector) => {
    const verifiedClaims = await collector.collect(currentState);
    if (verifiedClaims)
      verifiedClaims.forEach((verifiedClaim) =>
        addVerifiedClaim(uprn, verifiedClaim)
      );
  };

  const claimsForPath = (path) => jp.get(currentClaimsMap, path);

  const headingText = () => {
    const address =
      currentState.propertyPack?.materialFacts?.propertyToBeSold?.address;
    if (address) return `${address.address1} ${address.address2}`;
    return "UPRN " + uprn;
  };

  const missingPropertyPaths = errors
    .filter(({ params }) => params.missingProperty)
    .map((error) => `$${error.dataPath}.${error.params.missingProperty}`);

  const collectors = availableCollectors(currentState);

  return (
    <>
      <Container sx={{ marginTop: "64px" }}>
        <Grid container justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <PropertyAvatar uprn={uprn} />
            <Typography variant="h5">{headingText()}</Typography>
          </Grid>
          <Grid item>
            <Button
              component={Link}
              to={`/properties/${property.uprn}`}
              variant="text">
              Normal View
            </Button>
          </Grid>
          <Grid item>
            {profile.myProperties[uprn] && (
              <Chip
                label={profile.myProperties[uprn].role}
                variant="outlined"
                color="primary"
              />
            )}
          </Grid>
        </Grid>

        <Container maxWidth="lg">
          <Tabs
            value={tab}
            onChange={(event, value) => setTab(value)}
            indicatorColor="primary"
            textColor="primary">
            <Tab label="Tasks" />
            <Tab label="Property" />
            <Tab label="Inbox" />
            <Tab label="Sharing" />
            <Tab label="*Data" />
            <Tab label="*Errors" />
            <Tab label="*ClaimsList" />
            <Tab label="*ClaimsMap" />
            <Tab label="*Geo view" />
            <Tab label="*StreetView" />
          </Tabs>
        </Container>

        <Container>
          <TabPanel value={tab} index={0}>
            <Typography variant="h5">My Tasks</Typography>
            {profile.myProperties[uprn] && (
              <List>
                {tasksForRole(profile.myProperties[uprn].role).map((task) => (
                  <ListItem
                    key={task.path}
                    onClick={() =>
                      history.push(
                        `/properties/${uprn}/tasks/${encodeURIComponent(
                          task.path
                        )}`
                      )
                    }>
                    <ListItemIcon>
                      {taskErrors[task.path]?.length === 0 &&
                      !missingPropertyPaths.includes(task.path) ? (
                        <TaskAlt style={{ fill: "green" }} />
                      ) : (
                        <CircleOutlined />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={task.name} />
                    <ListItemSecondaryAction>
                      <TasksRemainingCircle
                        number={taskErrors[task.path]?.length}
                      />
                      {claimsForPath(task.path) && (
                        <NavIconForClaims claims={claimsForPath(task.path)} />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </TabPanel>

          <TabPanel value={tab} index={1}>
            <DataView
              data={currentState.propertyPack}
              titles={uiSchema.propertyPack}
              claims={currentClaimsMap.propertyPack}
            />
          </TabPanel>

          <TabPanel value={tab} index={2}>
            <Typography variant="h5">Inbox</Typography>
            {collectors.length > 0 && (
              <List>
                {collectors.map((collector, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CloudDownload />
                    </ListItemIcon>
                    <ListItemText
                      primary={collector.name}
                      secondary={
                        collector.targetElement(currentState) === undefined
                          ? "Available"
                          : "Already exists"
                      }
                    />
                    <ListItemSecondaryAction>
                      <Button onClick={() => handleCollect(collector)}>
                        Collect
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </TabPanel>

          <TabPanel value={tab} index={3}>
            <SharingView profile={profile} uprn={uprn} />
          </TabPanel>

          <TabPanel value={tab} index={4}>
            <ReactJsonView
              src={currentState}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          </TabPanel>

          <TabPanel value={tab} index={5}>
            <ReactJsonView
              src={errors}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          </TabPanel>

          <TabPanel value={tab} index={6}>
            <Button variant="text" onClick={() => deleteClaimsForUprn(uprn)}>
              Clear Claims
            </Button>
            <ReactJsonView
              src={verifiedClaims}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          </TabPanel>

          <TabPanel value={tab} index={7}>
            <ReactJsonView
              src={currentClaimsMap}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          </TabPanel>

          <TabPanel value={tab} index={8}>
            <GeometryView uprn={uprn} />
          </TabPanel>

          <TabPanel value={tab} index={9}>
            <StreetView uprn={uprn} />
          </TabPanel>
        </Container>
      </Container>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          display: { xs: "block", sm: "none" }
        }}
        elevation={3}>
        <BottomNavigation
          showLabels
          value={tab}
          onChange={(event, newValue) => {
            setTab(newValue);
          }}>
          <BottomNavigationAction label="Tasks" icon={<PlaylistAddCheck />} />
          <BottomNavigationAction label="Property" icon={<HomeWork />} />
          <BottomNavigationAction label="Inbox" icon={<Inbox />} />
          <BottomNavigationAction label="Sharing" icon={<People />} />
        </BottomNavigation>
      </Paper>
    </>
  );
}
