import React from "react";
import { Tooltip } from "@mui/material";

function TasksRemainingCircle({ number }) {
  if (number === 0) return null;
  return (
    <Tooltip
      arrow
      title={`${number} task${number === "1" ? "" : "s"} tasks remaining`}>
      <svg height="22" width="30">
        <circle
          cx="11"
          cy="11"
          r="10"
          fill="#652492"
          // stroke="#555"
          // strokeWidth={1}
        />
        <text textAnchor="middle" fontSize="small" x="11" y="16" fill="#fff">
          {number}
        </text>
      </svg>
    </Tooltip>
  );
}

export default TasksRemainingCircle;
