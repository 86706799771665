import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import mapboxgl from "mapbox-gl";
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
// solves https://github.com/mapbox/mapbox-gl-js/issues/10565

mapboxgl.accessToken =
  "pk.eyJ1IjoiZWRtb2x5bmV1eCIsImEiOiJja245MDkwbXkweGFhMnBuemszMjV1ZGZmIn0.yVEpySQr_VZKWgHPgu5wlg";
const apiKey = "TZA40bKEW3o3OLgeAVoMyG34XD5SNmTz";
const serviceUrl = "https://api.os.uk/maps/vector/v1/vts";

function GeometryView({ currentState, height, property }) {
  const mapContainerRef = useRef(null);
  // const map = useRef(null);
  // const firstGeometry =
  //   currentState.propertyPack?.titlesToBeSold?.[0]?.titleExtents[0];
  // const planningApplicationFeatures =
  //   currentState.propertyPack.relevantPlanningApplications;

  const MapWrapper = styled.div`
    height: ${height - 48}px;
    width: 100%;
    border-radius: 16px;
    // opacity: 50%;
    // box-shadow: 0 -3px 10px rgb(0 0 0 / 0.2);
  `;

  useEffect(() => {
    const coordinates = [property.latitude, property.longitude];
    const firstGeometry =
      currentState?.propertyPack?.titlesToBeSold?.[0]?.titleExtents?.[0];

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      // style: "mapbox://styles/mapbox/streets-v11", // style URL
      style: serviceUrl + "/resources/styles?key=" + apiKey,
      center: [coordinates[1], coordinates[0]],
      zoom: 18,
      pitch: 60,
      interactive: false,
      transformRequest: (url) => {
        return {
          url: url + "&srs=3857"
        };
      }
    });
    function rotateCamera(timestamp) {
      // clamp the rotation between 0 -360 degrees
      // Divide timestamp by 100 to slow rotation to ~10 degrees / sec
      map.rotateTo((timestamp / 200) % 360, { duration: 0 });
      // Request the next frame of the animation.
      requestAnimationFrame(rotateCamera);
    }
    // map.touchZoomRotate.enable({ around: "center" });
    map.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false
      })
    );
    map.on("load", () => {
      rotateCamera(0);
      new mapboxgl.Marker({ color: "#481449" })
        .setLngLat([coordinates[1], coordinates[0]])
        .addTo(map);
      if (firstGeometry) {
        map.addSource("boundary-data", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [firstGeometry]
          }
        });
        map.addLayer({
          id: "boundary",
          type: "fill",
          source: "boundary-data",
          layout: {},
          paint: {
            "fill-color": "#0080ff",
            "fill-opacity": 0.3
          }
        });
        map.addLayer({
          id: "outline",
          type: "line",
          source: "boundary-data",
          layout: {},
          paint: {
            "line-color": "#000",
            "line-width": 1
          }
        });
      }
      // if (planningApplicationFeatures) {
      //   planningApplicationFeatures.features.forEach((feature) => {
      //     new mapboxgl.Marker({ color: "blue" })
      //       .setLngLat(feature.geometry.coordinates)
      //       .setPopup(
      //         new mapboxgl.Popup().setHTML(
      //           `<h3>${feature.properties.address}</h3>
      //           <p>${feature.properties.description} (${feature.properties.app_type})<br/>
      //           Status: ${feature.properties.app_state}  ${feature.properties.decided_date}</br>
      //           <a href=${feature.properties.url} target="_blank">Source</a> checked on ${feature.properties.last_scraped}</p>`
      //         )
      //       )
      //       .addTo(map);
      //   });
      // }
      // map.flyTo({ zoom: 18, curve: 1.42 });
    });
    map.on("style.load", () => {
      map.addLayer({
        id: "OS/TopographicArea_1/Building/1_3D",
        type: "fill-extrusion",
        source: "esri",
        "source-layer": "TopographicArea_1",
        filter: ["==", "_symbol", 33],
        minzoom: 16,
        layout: {},
        paint: {
          "fill-extrusion-color": "#DCD7C6",
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            16,
            0,
            16.05,
            ["get", "RelHMax"]
          ],
          "fill-extrusion-opacity": 0.4
        }
      });
    });

    // clean up on unmount
    return () => map.remove();
  });

  if (!property || !currentState) return null;
  return <MapWrapper ref={mapContainerRef}></MapWrapper>;
}

export default GeometryView;
