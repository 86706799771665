import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import WithLayout from "./WithLayout";
// Available layouts
// import Main from "./layouts/Main";
import Auth from "./layouts/Auth";
import Plain from "./layouts/Plain";
import Blank from "./layouts/Blank";

// Pages
import Home from "./views/site/Home";
// import ComingSoon from "./views/site/ComingSoon";
import NotFound from "./views/site/NotFound";

// Old Authentication pages
import Login from "./views/auth/Login";
import Signup from "./views/auth/Signup";
import Verify from "./views/auth/Verify";
import ForgotPassword from "./views/auth/ForgotPassword";

import HomeView from "./views/app/HomeView";
import SearchView from "./views/app/SearchView";
import PropertyView from "./views/app/PropertyView";
import DevView from "./views/app/DevView";
import FormTaskView from "./views/app/FormTaskView";
import ClaimsView from "./views/app/ClaimsView";

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={HomeView} layout={Plain} />
        )}
      />
      <Route
        exact
        path="/home"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={Home} layout={Plain} />
        )}
      />
      <Route
        exact
        path="/properties/:uprn"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={PropertyView} layout={Blank} />
        )}
      />
      <Route
        exact
        path="/properties/dev/:uprn"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={DevView} layout={Plain} />
        )}
      />
      <Route
        exact
        path="/properties/:uprn/tasks/form/:encodedPath"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={FormTaskView} layout={Plain} />
        )}
      />
      <Route
        exact
        path="/properties/:uprn/claims/:encodedPath"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={ClaimsView} layout={Plain} />
        )}
      />
      <Route
        exact
        path="/search"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={SearchView} layout={Plain} />
        )}
      />
      <Route
        exact
        path="/login"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={Login} layout={Auth} />
        )}
      />
      <Route
        exact
        path="/signup"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={Signup} layout={Auth} />
        )}
      />
      <Route
        exact
        path="/verify"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={Verify} layout={Auth} />
        )}
      />
      <Route
        exact
        path="/forgot-password"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={ForgotPassword}
            layout={Auth}
          />
        )}
      />
      <Route
        exact
        path="/page-not-found"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={NotFound} layout={Auth} />
        )}
      />
      <Redirect to="/page-not-found" />
    </Switch>
  );
};

export default Routes;
