import { useContext } from "react";
// import schema1_0_0 from "./pdtf/pdtf-1.0.0";
import property_pack_1_0_2 from "./pdtf/property-pack-1.0.2";
import { dereference } from "@jdw/jst";
import validatorContext from "../contexts/validatorContext";

export const currentSchemaId =
  "https://homebuyingandsellinggroup.co.uk/schemas/property-pack-1.0.2.json";

export const useCurrentSchema = () => {
  const { validator } = useContext(validatorContext);
  return dereference(property_pack_1_0_2, (id) => {
    // console.log("in deref", id);
    return validator.getSchema(id).schema;
  });
};

export const subSchema = (schema, path) => {
  // assuming the schema is fully dereferenced
  const pathArray = path.split("/").slice(1);
  if (pathArray.length < 1) return schema;
  const sub = pathArray.reduce(
    (subSchemaAcc, pathElement) => subSchemaAcc["properties"][pathElement],
    schema
  );
  return sub;
};
