import { db, functions } from "../firebase";
import { currentSchemaId } from "../schemas/schemaUtils";

const registerExtractCollector = async (currentState) => {
  const uprn = currentState.uprn.toString();
  const doc = await db.collection("properties").doc(uprn).get();
  const propertyRecord = await doc.data();
  console.log("calling");
  const titleCallable = functions.httpsCallable(
    "hmlr-enquiryByPropertyDescription"
  );
  const result = await titleCallable(propertyRecord);
  let titles = result.data.Results.Title;
  console.log(titles);
  if (!Array.isArray(titles)) titles = [titles];

  const titleResults = titles.map((title) => {
    return {
      titleNumber: title.TitleNumber
    };
  });

  const evidence = [
    {
      type: "electronic_record",
      record: {
        type: "hmlr",
        source: {
          name: "HM Land Registry"
        }
      }
    }
  ];

  const titleNumberClaim = {
    // uprn,
    verification: {
      trust_framework: "uk_pdtf",
      time: new Date().toISOString(),
      evidence
    },
    claims: {
      schema: currentSchemaId,
      path: "/propertyPack/titlesToBeSold",
      data: titleResults,
      attribution:
        "Information produced by HM Land Registry. © Crown copyright and database rights 2022"
    }
  };

  console.log(titleNumberClaim);
  return [titleNumberClaim];
};

export default registerExtractCollector;
