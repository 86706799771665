export const ROLES = {
  LENDER: "Lender",
  BROKER: "Mortgage Broker",
  BUYER: "Buyer",
  BUYERS_SOLICITOR: "Buyer's Solicitor",
  SELLERS_SOLICITOR: "Seller's Solicitor",
  SELLER: "Seller",
  ESTATE_AGENT: "Estate Agent",
  SURVEYOR: "Surveyor"
};

export const TASKS = {};

export const ESTATE_INTEREST_DECODE = {
  EL: {
    name: "Estate in land",
    description:
      "A legal estate describes the ownership of land and the duration for which land is to be held. Estates can be freehold or leasehold. Freehold estates are held for an unknown or infinite duration. Leasehold estates have a fixed or maximum duration"
  },
  FA: {
    name: "Affecting franchise",
    description:
      "A franchise is a right granted by the Crown. An affecting franchise relates to a defined area of land. It is an adverse right affecting, or capable of affecting, the title to an estate or charge. A franchise does not carry with it ownership of the physical land. It is separate from the freehold or leasehold estates in land"
  },
  FC: {
    name: "Relating franchise",
    description:
      "A franchise is a right granted by the Crown. A relating franchise does not relate to a defined area of land. A franchise does not carry with it ownership of the physical land and is distinct from the freehold or leasehold estates in land"
  },
  MA: {
    name: "Manor",
    description:
      "Manors are of ancient origin dating from before Norman times. A manor was self-contained with its own customs and rights within its defined area. The LR Act 2002 has no power to register a manor"
  },
  PG: {
    name: "Profit a prendre in gross",
    description:
      "A profit a prendre is the right to take something from the land of another e.g. the right to fish from a river or to hunt game. Profit a prendre in gross exist independently in their own right"
  },
  RC: {
    name: "Rentcharge",
    description:
      "A rentcharge is a sum of money, payable either annually or biannually, which is a charge on land. A rentcharge is usually created when land changes ownership. The party selling the land reserves an annual rent which is payable to them and their successors in title"
  },
  RP: {
    name: "Rentcharge (Perpetual)",
    description:
      "A perpetual rentcharge describes a rentcharge intended to be payable indefinitely"
  },
  RT: {
    name: "Rentcharge (Terminable)",
    description:
      "A terminable rentcharge describes a rentcharge granted for a fixed number of years"
  }
};

export const CLASS_OF_TITLE_DECODE = {
  AF: {
    name: "Absolute freehold title",
    description:
      "This is the best class of freehold title. An application based on a purchase of unregistered land for value by the estate owner or a predecessor in title and supported by a proper documentary title commencing with a good root of at least 15 years old at the date of acquisition will normally result in the grant of an absolute title"
  },
  QF: {
    name: "Qualified freehold title",
    description:
      "This class of title may be approved where a person has applied for an absolute title but on examination it appears to the Registrar that the title is affected by some specific defect which he cannot disregard under his power to register a title open to objection"
  },
  PF: {
    name: "Possessory freehold title",
    description:
      "This class of title is most likely to be approved in two cases: Where the applicant claims adverse possession; Where the title deeds have been lost or destroyed.In each case the lack of conventional documentary title means there is a risk that the title may be affected by undisclosed adverse interests"
  },
  AL: {
    name: "Absolute leasehold title",
    description:
      "This is the best class of leasehold title. Absolute leasehold title will only be granted if: we are satisfied that title to the estate is such as a willing buyer could properly be advised by a competent professional adviser to accept, we approve the lessor's title to grant the lease (section 10(2) of the Land Registration Act 2002)"
  },
  GL: {
    name: "Good leasehold title",
    description:
      "Good leasehold is granted where the lessee's right to assign the lease has been shown but the evidence is not such as to satisfy the registrar that the criteria for Absolute Leasehold has been met"
  },
  QL: {
    name: "Qualified leasehold title",
    description:
      "This class of title may be approved where on examination it appears to the Registrar that the title of the lessor to the reversion or of the lessee to the leasehold interest is subject to some specific defect"
  },
  PL: {
    name: "Possessory leasehold title",
    description:
      "This class of title may be granted where the applicant title is primarily dependant, not upon a conventional documentary title, but upon them being in possession of land which is comprised in a lease. It may be approved where the leasehold documents have been lost or destroyed or where a squatter claims to have acquired a leasehold title by adverse possession"
  },
  SF: {
    name: "Scheme Title Freehold",
    description:
      "A scheme title describes the process that allows a public authority, private developer or other organisation to apply to register a defined area of land acquired piecemeal over a period of time and build up its title as land is acquired. A freehold scheme title is an acquisition of freehold land"
  },
  CF: {
    name: "Commonhold Freehold",
    description:
      "A form of land ownership of registered freehold land. It gives owners of individual units in a development the security of freehold ownership but allows them to control and collectively manage their own common areas and to apply positive obligations to every successive owner of the individual units in the development"
  },
  SL: {
    name: "Scheme Title Leasehold",
    description:
      "A scheme title describes the process that allows a public authority, private developer or other organisation to apply to register a defined area of land acquired piecemeal over a period of time and build up its title as land is acquired. A leasehold scheme title is an acquisition of leasehold land"
  },
  CL: {
    name: "Commonhold Leasehold",
    description:
      "A form of land ownership of registered leasehold land. It gives owners of individual units in a development the security of freehold ownership but allows them to control and collectively manage their own common areas and to apply positive obligations to every successive owner of the individual units in the development"
  },
  AR: {
    name: "Absolute Rentcharge",
    description:
      "A rentcharge is any sum of money charged on land payable either annually or periodically, other than rent reserved by a lease or interest charged under a charge. An absolute rentcharge is the best class of rentcharge"
  },
  PR: {
    name: "Possessory Rentcharge",
    description:
      "A rentcharge is any sum of money charged on land payable either annually or periodically, other than rent reserved by a lease or interest charged under a charge. A possessory rentcharge may be applicable where conventional documentary evidence of the rentcharge is not available"
  },
  QR: {
    name: "Qualified Rentcharge",
    description:
      "A rentcharge is any sum of money charged on land payable either annually or periodically, other than rent reserved by a lease or interest charged under a charge. A qualified rentcharge is applicable where an absolute rentcharge has been applied for but on examination it appears to the Registrar that the application has some defect"
  },
  CN: {
    name: "Caution Against First Registration",
    description:
      "Describes an interest in an unregistered legal estate, so that the cautioner will receive notice by the Registrar of any application for first registration of that estate"
  }
};
