import React from "react";

function StreetView({ transaction }) {
  const coordinates =
    transaction.currentState.propertyPack?.materialFacts?.propertyToBeSold
      ?.geographicCoordinates?.coordinates;
  if (!coordinates) return null;
  const googleMapsApiKey = "AIzaSyDSz-BxsKrAKOzwiHUGbWlufToWTWdqcnI";
  const mapQuery = `https://maps.googleapis.com/maps/api/streetview?size=300x300&key=${googleMapsApiKey}&location=${coordinates[0]},${coordinates[1]}`;

  return <img src={mapQuery} alt="Property map view" width="100%" />;
}

export default StreetView;
