import React from "react";
import styled from "@emotion/styled";

import { CircularProgress, Box } from "@mui/material";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function Loader(props) {
  return (
    <Root>
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress m={2} color="primary" size={props.size || 50} />
      </Box>
    </Root>
  );
}

export default Loader;
