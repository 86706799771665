import styled from "@emotion/styled";

const googleMapsApiKey = "AIzaSyDSz-BxsKrAKOzwiHUGbWlufToWTWdqcnI";
const AvatarImage = styled.img`
  border-radius: 20%;
  margin-right: 8px;
  width: 70px;
  height: 70px;
  object-fit: none;
`;

export default function PropertyAvatar({ property }) {
  if (!property) return null;
  const mapQuery = `https://maps.googleapis.com/maps/api/streetview?size=120x120&key=${googleMapsApiKey}&location=${property.latitude},${property.longitude}`;

  return <AvatarImage src={mapQuery} alt="Property avatar" />;
}
