// import { responsiveFontSizes } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import shadows from "./shadows";
import { light as lightIndigo, dark as darkIndigo } from "./palette--indigo";

const getTheme = (mode, paletteType) =>
  // responsiveFontSizes(
  createTheme({
    palette: mode === "light" ? lightIndigo : darkIndigo,
    layout: {
      contentWidth: 1236
    },
    shadows: shadows(mode),
    typography: {
      fontFamily: "Poppins, sans-serif",

      h1: {
        fontSize: "2.0rem",
        fontWeight: 600,
        lineHeight: "1.2",
        color: "#1C1D1F"
      },
      h2: {
        fontSize: "1.5rem",
        fontWeight: 500,
        lineHeight: "1.1",
        color: "#1C1D1F"
      },
      h3: { fontSize: "1.2rem", fontWeight: 400, lineHeight: "1.2" },
      h4: { fontSize: "1.1rem", fontWeight: 400 },
      h5: { fontSize: "1.0rem", fontWeight: 400 },
      h6: { fontSize: "0.9rem", fontWeight: 400 },
      body1: { fontSize: "1.0rem", fontWeight: 400 },
      body2: { fontSize: "0.9rem", fontWeight: 400 },
      subtitle: {
        fontSize: "0.9rem",
        fontWeight: 400,
        color: "#8691A8",
        lineHeight: "0.8"
      },
      button: {
        textTransform: "none",
        fontWeight: "medium"
      }
    },
    zIndex: {
      appBar: 1200,
      drawer: 1300
    },
    components: {
      MuiButton: {
        styleOverrides: {
          label: {
            fontWeight: 600
          },
          containedSecondary: mode === "light" ? { color: "white" } : {}
        }
      }
    }
  });
// );

export default getTheme;
