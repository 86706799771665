import { db } from "../firebase";
import { currentSchemaId } from "../schemas/schemaUtils";
import md5 from "crypto-js/md5";

const epcCollector = async (currentState) => {
  const EPC_0_1_0 = require("../schemas/pdtf/energy-performance-certificate-0.1.0.json");

  const coerceTypes = (key, value) => {
    switch (EPC_0_1_0.properties.certificate.properties[key]?.type) {
      case "integer":
        return parseInt(value, 10);
      case "number":
        return parseFloat(value);
      default:
        return value;
    }
  };

  // TODO fetch (scrape!) validity information from https://epc.opendatacommunities.org
  const uprn = currentState.uprn.toString(); // need toString?
  console.log("in EPC cert collector");
  const doc = await db.collection("properties").doc(uprn).get();
  const property = await doc.data();
  let headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append(
    "Authorization",
    "Basic ZWRAdmV5YW5jZXIuY29tOjc0MDU1OTQ0NzEzY2NlN2E3MWMzZDYzZGEzZGQ3MzNiODc1YjVkNGM="
  );
  const postcode = property.postcode;
  if (!postcode) return null;
  let url =
    "https://epc.opendatacommunities.org/api/v1/domestic/search?postcode=";
  let query = postcode.replace(/\s/g, "");
  let response = await fetch(url + query, {
    method: "GET",
    headers: headers
  });
  let json = await response.json();
  console.log(JSON.stringify(json));
  const match = json.rows.find((row) => row.uprn === uprn);
  // console.log(JSON.stringify(match));

  url =
    "https://epc.opendatacommunities.org/api/v1/domestic/certificate/" +
    match["lmk-key"];
  response = await fetch(url, {
    method: "GET",
    headers: headers
  });
  const rawEPCResponse = await response.text(); // need this to hash it
  console.log(rawEPCResponse);
  const epcResponseJson = JSON.parse(rawEPCResponse);
  const text = JSON.stringify(epcResponseJson.rows[0]);
  console.log(text);
  const data = JSON.parse(text, coerceTypes);
  // console.log(JSON.stringify(json, null, 2));

  // url =
  //   "https://epc.opendatacommunities.org/api/v1/domestic/recommendations/" +
  //   match["lmk-key"];
  // response = await fetch(url, {
  //   method: "GET",
  //   headers: headers
  // });
  // const data = await response.json();
  // console.log(JSON.stringify(json.rows, null, 2));

  const sourceUri =
    "https://epc.opendatacommunities.org/domestic/certificate/" +
    match["lmk-key"];

  const evidence = [
    {
      type: "electronic_record",
      record: {
        type: "epc",
        source: {
          name: "Department for Levelling Up, Housing & Communities, Energy Performance of Buildings Register"
        }
      },
      // non-standard use of external attachment element which is normally based on OAuth2
      attachments: [
        {
          desc: "Response from Opendata Communities EPC Register in JSON format",
          digest: { alg: "md5", value: md5(rawEPCResponse).toString() },
          url: sourceUri,
          // non standard verified claims param as that's based on Oauth2
          auth_header:
            "Basic ZWRAdmV5YW5jZXIuY29tOjc0MDU1OTQ0NzEzY2NlN2E3MWMzZDYzZGEzZGQ3MzNiODc1YjVkNGM="
        }
      ]
    }
  ];

  const epcCertificateClaim = {
    uprn,
    verification: {
      trust_framework: "uk_pdtf",
      time: new Date().toISOString(),
      evidence
    },
    claims: {
      schema: currentSchemaId,
      path: "/propertyPack/energyPerformanceCertificate",
      data,
      attribution: "https://epc.opendatacommunities.org/docs/copyright"
    }
  };

  const epcRatingClaim = {
    // uprn,
    verification: {
      trust_framework: "uk_pdtf",
      time: new Date().toISOString(),
      evidence
    },
    claims: {
      schema: currentSchemaId,
      path: "/propertyPack/materialFacts/propertyToBeSold/energyEfficiency/energyEfficiencyRating",
      data: data["current-energy-rating"],
      attribution: "https://epc.opendatacommunities.org/docs/copyright"
    }
  };

  return [epcCertificateClaim, epcRatingClaim];
};

export default epcCollector;
