import React, { useState, useContext } from "react";
import { auth, db } from "../../firebase";
import { Link, useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

import {
  Button as MuiButton,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField as MuiTextField,
  Typography as MuiTypography
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import Logo from "../../assets/veyancer_wordandlogosolid28.png";
import userContext from "../../contexts/userContext";
import { LoginOrSignupWithGoogle } from "../../util/authMethods";
// import trackEvent from "../../util/trackEvent";

const Wrapper = styled(Paper)`
  padding: 20px 60px 8px;
`;
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);

export default function LoginScreen() {
  const { setProfile } = useContext(userContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const history = useHistory();

  const handleLogin = () => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        console.log("signin res ", response);
        return db.collection("users").doc(response.user.uid).get();
      })
      .then((doc) => {
        setProfile(doc.data());
        history.push("/");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setError(error.message);
      });
  };

  const buttonDisabled = () => {
    return !(email && /\S+@\S+\.\S+/.test(email) && password && !loading);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // if (user) return <Redirect to="/" />;
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignContent="center"
      direction="column"
      spacing={6}>
      <Grid item>
        <Wrapper elevation={0}>
          <Container maxWidth="xs">
            <Helmet title="Login to Veyancer" />
            <Grid
              container
              justifyContent="center"
              onClick={() => history.push("/")}>
              <img src={Logo} alt="Veyancer logo" />
            </Grid>
            <Typography
              component="h1"
              variant="h6"
              align="center"
              gutterBottom
              my={3}>
              Login
            </Typography>
            <TextField
              mb={2}
              required
              autoFocus
              id="email"
              name="email"
              type="email"
              label="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              fullWidth
            />
            <TextField
              mb={2}
              required
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              label="Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {error && (
              <Typography mb={2} variant="body2">
                {error}
              </Typography>
            )}
            <Grid container justifyContent="center">
              <Button
                my={4}
                variant="contained"
                fullWidth
                onClick={handleLogin}
                color="primary"
                size="large"
                disabled={buttonDisabled()}>
                {loading ? <CircularProgress size={30} /> : "Log in"}
              </Button>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  size="small"
                  href="/auth/reset-password"
                  color="secondary">
                  Forgot your password?
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  Need an account?
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  pb={0.5}
                  size="small"
                  component={Link}
                  to="/signup"
                  color="secondary">
                  Signup here
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Wrapper>
      </Grid>
      <Grid item container justifyContent="space-evenly" alignItems="center">
        <Grid item xs={5}>
          <Divider />
        </Grid>
        <Grid item xs={2}>
          <Typography
            variant="h6"
            style={{ color: "#afafaf", textAlign: "center" }}>
            or
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Divider />
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="center">
          <LoginOrSignupWithGoogle />
        </Grid>
      </Grid>
    </Grid>
  );
}
