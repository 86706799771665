import React from "react";
import { Link } from "react-router-dom";
// import { format } from "date-fns";

import { Tooltip, Icon, Typography } from "@mui/material";

import {
  ShieldOutlined as Shield,
  VerifiedUserOutlined as TickedShield,
  SecurityOutlined as SecureShield
} from "@mui/icons-material";

export const IconForClaim = (verifiedClaim) => {
  const evidence = verifiedClaim.verification.evidence;
  if (!evidence) return null;
  switch (
    evidence.slice(-1)[0].type // last item in array
  ) {
    case "vouch":
      return <Shield style={{ fill: "Gray" }} />;
    case "document":
      return <TickedShield style={{ fill: "Gray" }} />;
    case "electronic_record":
      return <TickedShield style={{ fill: "Green" }} />;
    default:
      // verifiable digital
      return <SecureShield style={{ fill: "Green" }} />;
  }
};

export const plainTextForClaim = (verifiedClaim) => {
  const evidence = verifiedClaim.verification.evidence;
  if (!evidence) return "No evidence";
  switch (evidence.slice(-1)[0].type) {
    case "vouch":
      return `From ${evidence[0].attestation.voucher.name} on ${verifiedClaim.verification.time}`;
    case "document":
      return `Documented by ${evidence[0].attestation.voucher.name} on ${verifiedClaim.verification.time}`;
    case "electronic_record":
      return `Digitally collected from ${evidence[0].record.source.name} on ${verifiedClaim.verification.time}`;
    default:
      // verifiable digital
      return `Digitally verified from ${evidence[0].record.source.name} on ${verifiedClaim.verification.time}`;
  }
};

export const shortTextForClaim = (verifiedClaim) => {
  // console.log(verifiedClaim);
  const evidence = verifiedClaim.verification.evidence;
  if (!evidence) return "No evidence";
  switch (evidence.slice(-1)[0].type) {
    case "vouch":
      return "Complete";
    case "document":
      return "Complete (with documents)";
    case "electronic_record":
      return "Digital Source";
    default:
      // verifiable digital
      return "Verified Digital Source";
  }
};

export const ShortTypographyForClaims = (claims) => {
  return (
    <Typography variant="subtitle" color="success.main">
      {shortTextForClaim(latestClaim(claims))}
    </Typography>
  );
};

const latestClaim = (claims) => {
  return claims ? claims[claims.length - 1] : null;
};

const IconForClaims = (claims) => IconForClaim(latestClaim(claims));
// display latest claim icon - is it sorted thougjh?

function NavIconForClaims({ claims }) {
  if (!claims) return null;
  const { uprn, path } = latestClaim(claims);

  return (
    <Tooltip title={plainTextForClaim(latestClaim(claims))} arrow>
      <Icon
        edge="end"
        sx={{ marginRight: "8px" }}
        component={Link}
        to={`/properties/${uprn}/claims/${encodeURIComponent(path)}`}>
        {IconForClaims(claims)}
      </Icon>
    </Tooltip>
  );
}

export default NavIconForClaims;
