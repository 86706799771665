import { ROLES } from "../constants";

export const tasksForRole = (role) => {
  return allTasks.filter((task) => role === task.role);
};

export const allTasks = [
  {
    name: "Address",
    path: "/propertyPack/materialFacts/propertyToBeSold/address",
    role: ROLES.SELLER,
    forceSingleStep: true
  },
  {
    name: "Delay Factors",
    path: "/propertyPack/materialFacts/propertyToBeSold/delayFactors",
    role: ROLES.SELLER,
    forceSingleStep: true
  },
  {
    name: "Ownership",
    path: "/propertyPack/materialFacts/propertyToBeSold/ownership",
    role: ROLES.SELLER
  },
  {
    name: "Parking Arrangements",
    path: "/propertyPack/materialFacts/propertyToBeSold/parkingArrangements",
    role: ROLES.SELLER
  },
  {
    name: "Listing and Conservation",
    path: "/propertyPack/materialFacts/propertyToBeSold/listingAndConservation",
    role: ROLES.SELLER
  },
  {
    name: "Type of Construction",
    path: "/propertyPack/materialFacts/propertyToBeSold/typeOfConstruction",
    role: ROLES.SELLER
  },
  {
    name: "Energy Efficiency",
    path: "/propertyPack/materialFacts/propertyToBeSold/energyEfficiency",
    role: ROLES.SELLER
  },
  {
    name: "Council Tax",
    path: "/propertyPack/materialFacts/propertyToBeSold/councilTax",
    role: ROLES.SELLER
  },
  {
    name: "Disputes and Complaints",
    path: "/propertyPack/materialFacts/disputesAndComplaints",
    role: ROLES.SELLER
  },
  {
    name: "Alterations and Changes",
    path: "/propertyPack/materialFacts/alterationsAndChanges",
    role: ROLES.SELLER
  },
  {
    name: "Notices",
    path: "/propertyPack/materialFacts/notices",
    role: ROLES.SELLER
  },
  {
    name: "Specialist Issues",
    path: "/propertyPack/materialFacts/specialistIssues",
    role: ROLES.SELLER
  },
  {
    name: "Fixtures and Fittings",
    path: "/propertyPack/materialFacts/fixturesAndFittings",
    role: ROLES.SELLER
  },
  {
    name: "Water and Drainage",
    path: "/propertyPack/materialFacts/waterAndDrainage",
    role: ROLES.SELLER
  },
  {
    name: "Utilities",
    path: "/propertyPack/materialFacts/utilities",
    role: ROLES.SELLER
  },
  {
    name: "Green Deal Loan",
    path: "/propertyPack/materialFacts/greenDealLoan",
    role: ROLES.SELLER
  },
  {
    name: "Central Heating",
    path: "/propertyPack/materialFacts/centralHeating",
    role: ROLES.SELLER
  },
  {
    name: "Insurance",
    path: "/propertyPack/materialFacts/insurance",
    role: ROLES.SELLER
  },
  {
    name: "Boundaries",
    path: "/propertyPack/materialFacts/boundaries",
    role: ROLES.SELLER
  },
  {
    name: "Rights and Informal Arrangements",
    path: "/propertyPack/materialFacts/rightsAndInformalArrangements",
    role: ROLES.SELLER
  },
  {
    name: "Other Issues",
    path: "/propertyPack/materialFacts/otherIssues",
    role: ROLES.SELLER
  },
  {
    name: "Additional Information",
    path: "/propertyPack/materialFacts/additionalInformation",
    role: ROLES.SELLER
  },
  {
    name: "Consumer Protections Regulations Declaration",
    path: "/propertyPack/materialFacts/consumerProtectionRegulationsDeclaration",
    role: ROLES.SELLER,
    forceSingleStep: true
  },
  {
    name: "Legal Ownership",
    path: "/propertyPack/additionalLegalInfo/legalOwnership/namesOfLegalOwners",
    role: ROLES.SELLER,
    forceSingleStep: true
  },
  {
    name: "Seller's Conveyancer",
    path: "/propertyPack/additionalLegalInfo/legalOwnership/sellersConveyancer",
    role: ROLES.SELLER,
    forceSingleStep: true
  },
  {
    name: "Seller's Capacity",
    path: "/propertyPack/additionalLegalInfo/legalOwnership/sellersCapacity",
    role: ROLES.SELLER
  },
  {
    name: "Property Boundaries",
    path: "/propertyPack/additionalLegalInfo/aboutTheProperty",
    role: ROLES.SELLER
  },
  {
    name: "Services Crossing",
    path: "/propertyPack/additionalLegalInfo/servicesCrossing",
    role: ROLES.SELLER
  },
  {
    name: "Energy and Meters",
    path: "/propertyPack/additionalLegalInfo/energyAndMeters",
    role: ROLES.SELLER
  },
  {
    name: "Guarantees, Warranties and Indemnity Insurances",
    path: "/propertyPack/additionalLegalInfo/guaranteesWarrantiesAndIndemnityInsurances",
    role: ROLES.SELLER
  },
  {
    name: "Occupiers",
    path: "/propertyPack/additionalLegalInfo/occupiers",
    role: ROLES.SELLER
  },
  {
    name: "Completion and Moving",
    path: "/propertyPack/additionalLegalInfo/completionAndMoving",
    role: ROLES.SELLER
  },
  {
    name: "Confirmation of accuracy",
    path: "/propertyPack/additionalLegalInfo/confirmationOfAccuracyByOwners",
    role: ROLES.SELLER
  }
];
