import { useState, useEffect } from "react";
import { db } from "../firebase";

import { useCollectionData } from "react-firebase-hooks/firestore";
import jp from "jsonpointer";
import { currentSchemaId, useCurrentSchema } from "../schemas/schemaUtils";

export const addVerifiedClaim = async (uprn, verifiedClaim) => {
  // can't just store the object, firestore doesn't support
  // directly nested arrays which are a feature of GeoJSON
  // verifiedClaim.claims.data = JSON.stringify(verifiedClaim.claims.data);
  // console.log(verifiedClaim);
  const response = await fetch(
    // `http://localhost:5001/veyancer/europe-west1/api/properties/${uprn}/claims`,
    `https://europe-west1-veyancer.cloudfunctions.net/api/properties/${uprn}/claims`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(verifiedClaim)
    }
  );
  console.log("add verified claim response ", response.status);
  // console.log(await response.json());
  // await db.collection("verifiedClaims").add(verifiedClaim);
};

// for demo purposes only
export const deleteClaim = async (claimId) => {
  await db.collection("verifiedClaims").doc(claimId).delete();
};

// for demo purposes only
export const deleteClaimsForUprn = async (uprn) => {
  const querySnapshot = await db
    .collection("verifiedClaims")
    .where("uprn", "==", uprn)
    .get();
  querySnapshot.forEach((doc) => doc.ref.delete());
};

const useVerifiedClaims = (uprn) => {
  const currentSchema = useCurrentSchema();
  const initialState = { $schema: currentSchema.$id, uprn: parseInt(uprn) };
  const [currentState, setCurrentState] = useState(initialState);
  const [currentClaimsMap, setCurrentClaimsMap] = useState({});

  const [verifiedClaims, loadingVerifiedClaims] = useCollectionData(
    db
      .collection("verifiedClaims")
      .where("uprn", "==", uprn)
      .orderBy("timestamp"),
    { idField: "id" }
  );

  useEffect(() => {
    if (
      loadingVerifiedClaims ||
      !verifiedClaims ||
      verifiedClaims?.length === 0
    )
      return null;
    const newState = initialState;
    const newClaimsMap = {};
    verifiedClaims.forEach((verifiedClaim) => {
      const data = JSON.parse(verifiedClaim.claims.data);
      // const existing = jp.get(newState, claim.path);
      // if (existing) {
      //   // existing data to merge
      //   console.log("found existing in ", claim.path);
      //   console.log("existing", existing);
      //   console.log("new", data);
      //   console.log("merged", { ...existing, ...data });

      //   jp.set(newState, claim.path, { ...existing, ...data });
      // } else {
      jp.set(newState, verifiedClaim.claims.path, data);
      // }
      if (verifiedClaim.claims.path.slice(-1) === "-") {
        // the array append marker
        jp.set(newClaimsMap, verifiedClaim.claims.path, verifiedClaim);
      } else {
        let provenances = jp.get(newClaimsMap, verifiedClaim.claims.path) || [];
        provenances.push(verifiedClaim);
        jp.set(newClaimsMap, verifiedClaim.claims.path, provenances);
      }
      setCurrentState(newState);
      setCurrentClaimsMap(newClaimsMap);
    });
  }, [verifiedClaims, uprn, loadingVerifiedClaims]);

  return [
    verifiedClaims,
    currentState,
    currentClaimsMap,
    loadingVerifiedClaims
  ];
};

export default useVerifiedClaims;
