import React, { useState } from "react";
import { db } from "../../firebase";
// import { useCollectionData } from "react-firebase-hooks/firestore";
import { useHistory } from "react-router-dom";
import { Client, autocomplete, udprn } from "@ideal-postcodes/core-browser";
import styled from "@emotion/styled";

import {
  List,
  ListItem as MuiListItem,
  Button,
  Container,
  TextField,
  ListItemText
} from "@mui/material";

// import userContext from "../../contexts/userContext";
// import PropertyMapView from "../../components/PropertyMapView";
import { sampleProperty } from "../../util/sampleProperty";

const ListItem = styled(MuiListItem)`
  // border: 1px solid LightGray;
  // margin-bottom: 16px;
  cursor: pointer;
  // background: white;
  &:hover {
    background-color: #ebebeb;
  }
`;

const idealPostcodesApiKey = "iddqd"; //"ak_ku74r5w4oOqrGn5Iwr8nuYRH1PU09";
const client = new Client(); // { api_key: "iddqd" }); // apiKey: "ak_ku74r5w4oOqrGn5Iwr8nuYRH1PU09"

// const MapContainer = styled.div`
// position: relative;
//   height: 100vh;
//   width: 100%;
//   }
// `;

// const MapOverlay = styled.div`
//   display: block;
//   position: absolute;
//   height: 80px;
//   width: 500px;
//   position: absolute;
//   top: 20px;
//   left: 50%;
//   margin-left: -250px; /*half the size of width*/
//   z-index: 1 !important;
// `;

export default function SearchView(props) {
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState();
  let history = useHistory();

  // useEffect(() => {
  //   AddressFinder.setup({
  //     inputField: document.getElementById("searchInput"),
  //     apiKey: "iddqd",
  //     // apiKey: "ak_ku74r5w4oOqrGn5Iwr8nuYRH1PU09"
  //     inputStyle: {
  //       width: "100%",
  //       padding: "10px",
  //       fontSize: "16px",
  //       borderRadius: "10px"
  //     },
  //     containerStyle: {},
  //     listStyle: {},
  //     injectStyle: true,
  //     onAddressSelected: () => setQuery(""),
  //     onSearchError: (e) => handleError(e),
  //     onAddressRetrieved: (address) => setAddress(address)
  //   });
  // }, []);

  const handleChange = (event) => {
    setQuery(event.target.value);
    autocomplete
      .list(client, {
        query: {
          query: event.target.value
        },
        header: {
          Authorization: `IDEALPOSTCODES api_key="${idealPostcodesApiKey}"`
        }
      })
      .then((response) => {
        setOptions(response.body.result.hits);
      })
      .catch((error) => console.log(error));
  };

  const handleSelected = (selectedUdprn) => {
    let address;
    udprn
      .retrieve(client, selectedUdprn, {
        header: {
          Authorization: `IDEALPOSTCODES api_key="${idealPostcodesApiKey}"`
        }
      })
      .then((response) => {
        address = response.body.result;
        // console.log(address);
        return db
          .collection("properties")
          .doc(address.uprn.toString())
          .set(address);
      })
      .then(() => {
        history.push(`/properties/${address.uprn}`);
      })
      .catch((error) => console.log(error));
  };

  const handleSampleProperty = () => {
    history.push(`/properties/${sampleProperty.uprn}`);
  };

  // const clearAddress = () => {
  //   setProperty(null);
  //   setQuery("");
  // };

  // const handleCreateAsSeller = async () => {
  //   const { firstName, lastName, email } = profile;
  //   const transactionId = uuidv4();
  //   const newTransaction = new Transaction(transactionId, {
  //     $schema: currentSchema["$id"],
  //     uprn: parseInt(property.uprn)
  //   });
  //   const data = {
  //     firstName,
  //     lastName,
  //     email
  //   };
  //   const provenance = {
  //     level: "Individual",
  //     firstName,
  //     lastName,
  //     email
  //   };
  //   await newTransaction.addClaim({
  //     path: "/propertyPack/additionalLegalInformation/legalOwnership/namesOfLegalOwners/-",
  //     data,
  //     provenance
  //   });
  //   let myProperties = profile.myProperties || {};
  //   myProperties[property.uprn] = { role: "Seller" };
  //   await db.collection("users").doc(profile.id).update({ myProperties });
  //   // console.log("New transaction created " + transactionId);
  //   history.push(`/properties/${property.uprn}`);
  // };

  return (
    <Container maxWidth="sm" sx={{ marginTop: "64px" }}>
      <TextField
        variant="standard"
        id="query"
        placeholder="Start typing an address"
        fullWidth
        value={query}
        onChange={handleChange}
      />
      <Button onClick={handleSampleProperty} variant="text">
        (sample)
      </Button>
      {options && (
        <List>
          {options.map((option) => (
            <ListItem
              sx={{ cursor: "pointer" }}
              key={option.udprn}
              onClick={() => handleSelected(option.udprn)}>
              <ListItemText primary={option.suggestion} />
            </ListItem>
          ))}
        </List>
      )}
      {/* <Container sx={{ display: property ? "block" : "none" }}>
        {property?.uprn &&
          profile?.myProperties &&
          profile.myProperties[property.uprn] && (
            <Button component={Link} to={`/properties/${property.uprn}`}>
              View property as {profile.myProperties[property.uprn].role}
            </Button>
          )}
        {property?.uprn &&
          (!profile?.myProperties || !profile.myProperties[property.uprn]) && (
            <>
              <Button
                component={Link}
                to={`/view/${property ? property.uprn : ""}`}
                variant="contained"
                disabled>
                Enter my invitation code
              </Button>
              <Button mode="outlined" onClick={handleCreateAsSeller}>
                I'm the Seller, start my Property Pack
              </Button>
            </>
          )}
        <Button onClick={clearAddress} variant="text">
          Search Again
        </Button>
      </Container> */}
    </Container>
  );
}
