import React, { useEffect, useState, useContext } from "react";
import { db } from "../../firebase";
import { Link } from "react-router-dom";

import {
  Container,
  Typography,
  Divider,
  Grid,
  Card,
  Stack,
  CardContent,
  CardActionArea
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import userContext from "../../contexts/userContext";
import PropertyCard from "../../components/PropertyCard";

function HomeView() {
  const { profile } = useContext(userContext);
  const [myProperties, setMyProperties] = useState([]);

  useEffect(() => {
    if (!profile || !profile.myProperties) return;
    const myUprns = Object.keys(profile?.myProperties);
    if (myUprns.length > 0) {
      db.collection("properties")
        .where("uprn", "in", myUprns)
        .get()
        .then((snapshot) => {
          return snapshot.docs.map((doc) => doc.data());
        })
        .then((results) => {
          setMyProperties(results);
        })
        .catch((err) => console.log(err));
    }
  }, [profile]);

  return (
    <Container sx={{ marginTop: "64px" }}>
      <Typography variant="h2">Home</Typography>
      <Divider
        sx={{
          marginY: 4
        }}
      />
      <Grid container spacing={3}>
        {profile &&
          Object.keys(myProperties).length > 0 &&
          myProperties.map((property, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <PropertyCard property={property} />
            </Grid>
          ))}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ height: "100%" }}>
            <CardActionArea
              component={Link}
              to={`/search`}
              sx={{ height: "100%" }}>
              <CardContent
                sx={{ height: "100%" }}
                component={Stack}
                direction="column"
                alignItems="center"
                justifyContent="center">
                <AddCircleIcon color="primary" sx={{ margin: 3 }} />
                <Typography gutterBottom variant="h4" component="div">
                  Add a property
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default HomeView;
