import titleExtentsCollector from "./titleExtentsCollector";
import registerExtractCollector from "./registerExtractCollector";
import addressDataCollector from "./addressDataCollector";
import epcCollector from "./epcCollector";
import epcCollector2 from "./epcCollector2";
import associatedTitleNumbersCollector from "./associatedTitleNumbersCollector";

const availableCollectors = (currentState) => {
  if (!currentState) return [];
  return allCollectors.filter(
    (collector) => collector.requiredElement(currentState) !== undefined
  );
};

const allCollectors = [
  {
    name: "Address of Property",
    requiredElement: (currentState) => currentState.uprn,
    targetElement: (currentState) =>
      currentState.propertyPack?.materialFacts?.propertyToBeSold?.address,
    collect: addressDataCollector
  },
  {
    name: "Associated Title Numbers",
    requiredElement: (currentState) =>
      currentState.propertyPack?.materialFacts?.propertyToBeSold?.address,
    targetElement: (currentState) =>
      currentState.propertyPack?.titlesToBeSold?.[0]?.titleNumber,
    collect: associatedTitleNumbersCollector
  },
  {
    name: "Register Extract from HMLR",
    requiredElement: (currentState) =>
      currentState.propertyPack?.titlesToBeSold?.[0]?.titleNumber,
    targetElement: (currentState) =>
      currentState.propertyPack?.titlesToBeSold?.[0]?.registerExtract,
    collect: registerExtractCollector
  },
  {
    name: "Registered Title Extents from HMLR NPS",
    requiredElement: (currentState) =>
      currentState.propertyPack?.titlesToBeSold?.[0]?.titleNumber,
    targetElement: (currentState) =>
      currentState.propertyPack?.titlesToBeSold?.[0]?.titleExtents,
    collect: titleExtentsCollector
  },
  {
    name: "Energy Performance Certificate",
    requiredElement: (currentState) => currentState.uprn,
    targetElement: (currentState) =>
      currentState.propertyPack?.energyPerformanceCertificate,
    collect: epcCollector
  },
  {
    name: "Energy Performance Certificate2",
    requiredElement: (currentState) => currentState.uprn,
    targetElement: (currentState) =>
      currentState.propertyPack?.materialFacts?.propertyToBeSold
        ?.energyEfficiency,
    collect: epcCollector2
  }
];

export default availableCollectors;
